import React from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

export const ControlledInput = ({
  id,
  type,
  value,
  label,
  helpText,
  handleFieldChange,
  disabled,
  variant = 'outlined',
  inputProps,
  InputProps,
}) => {
  return (
    <TextField
      id={id}
      value={value !== ('undefined' || undefined) ? value : ''}
      label={label}
      helperText={helpText}
      onChange={handleFieldChange}
      disabled={disabled}
      type={type}
      variant={variant}
      size="small"
      inputProps={inputProps}
      InputProps={InputProps}
    />
  );
};

export const ControlledCheckbox = ({value, handleFieldChange, id, fields}) => {
  const checked = value ? true : false;
  const handleChange = (e) => {
    handleFieldChange(e);
  };
  return <Checkbox checked={checked} onChange={handleChange} name={id} id={id} />;
};
