import {Typography, Box} from '@material-ui/core';
import {useContent} from '../../utils/hooks/useContent';

export const Instructions = ({text}) => {
  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();
  return (
    <Box mb={2}>
      <Typography variant="overline">{getUiText('instructions')}</Typography>
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
};
