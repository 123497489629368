import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '../navigation/AppBar';
import {ProductionTabs} from '../production_floor/ProductionTabs';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100%',
    height: '100%',
  },
  content: {
    maxHeight: 'calc(100vh - 120px)',
    overflowY: 'auto',
    width: '100%',
  },
}));

export default function Stage() {
  const classes = useStyles();
  return (
    <>
      <AppBar variant="company" />
      <div className={classes.root}>
        <div className={classes.content}>
          <ProductionTabs />
        </div>
      </div>
    </>
  );
}
