import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {TimeContext} from '../../context/TimeContext';
import {SocketContext} from '../../context/SocketContext';
import {useSocketChannel} from '../../utils/hooks/useSocketChannel';
import {TimerControls} from './TimerControls';
import {Time} from './Time';
import {useContent} from '../../utils/hooks/useContent';

const useStyles = makeStyles((theme) => ({
  mainTime: {
    fontSize: 36,
    fontWeight: 600,
  },
  cycleTime: {
    fontSize: 14,
    color: '#CFC3EC',
  },
}));

export const Timer = ({isAdmin = false, small = false}) => {
  const classes = useStyles();
  const timeContext = useContext(TimeContext);
  const socketContext = useContext(SocketContext);
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const [data, start, pause] = useSocketChannel('timer', socketContext.socket);
  const [cycleData] = useSocketChannel('cycleTime', socketContext.socket);

  // Get initial time. This is necessary if the timer is paused,
  // since it won't emit when paused.
  useEffect(() => {
    socketContext.socket.emit('get time');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get initial cycle time. This is necessary if the timer is paused,
  // since it won't emit when paused.
  useEffect(() => {
    socketContext.socket.emit('get cycle time');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    timeContext.setSeconds(data.value);
    timeContext.setIsTimerRunning(data.isActive);
  }, [data, timeContext]);

  let totalCycleSeconds;

  if (cycleData.active && data.value && cycleData.startTime !== data.value) {
    totalCycleSeconds = data.value - cycleData.startTime;
  } else if (cycleData.endTime) {
    totalCycleSeconds = cycleData.endTime - cycleData.startTime;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Time totalSeconds={data.value ? data.value : 0} variant={small ? 'h4' : 'h3'} className={classes.mainTime} />
        {totalCycleSeconds && (
          <Box display="flex" flexDirection="row" className={classes.cycleTime}>
            <Time totalSeconds={totalCycleSeconds} variant={'p'} />
            <Box ml={0.5}>{getUiText('cycle_time').toUpperCase()}</Box>
          </Box>
        )}
      </Box>
      {isAdmin && <TimerControls isActive={data.isActive} pause={pause} start={start} />}
    </Box>
  );
};
