import React, {useContext, useState, useEffect} from 'react';
import {InputLabel, MenuItem, FormControl, Select, TextField} from '@material-ui/core';
import {useContent} from '../../utils/hooks/useContent';
import {materials} from '../../utils/constants';
import {SocketContext} from '../../context/SocketContext';

export const MaterialsSelect = () => {
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();
  const [materialsSelection, setMaterialsSelection] = useState('');
  const [qtySelection, setQtySelection] = useState('0');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const socketContext = useContext(SocketContext);
  const socket = socketContext.socket;

  const handleMaterialsToAdd = (event) => {
    setMaterialsSelection(event.target.value);
  };

  const handleMaterialsQty = (event) => {
    setQtySelection(event.target.value);
  };

  const submitMaterialsToAdd = () => {
    socket.emit(`add materials`, {materials: materialsSelection, qty: qtySelection});
  };

  useEffect(() => {
    materialsSelection === '' || qtySelection === '0' ? setSubmitDisabled(true) : setSubmitDisabled(false);
  }, [materialsSelection, qtySelection]);

  return (
    <FormControl>
      <InputLabel id="materials">{getUiText('materials')}</InputLabel>
      <Select labelId="materials-label" id="materials" value={materialsSelection} onChange={handleMaterialsToAdd}>
        {materials.map((material) => (
          <MenuItem key={material} value={material}>
            {getUiText(material)}
          </MenuItem>
        ))}
      </Select>
      <TextField
        id="materials-qty"
        label="Quantity"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{inputProps: {min: 0}}}
        onChange={handleMaterialsQty}
      />
      <button id="add-materials" onClick={submitMaterialsToAdd} disabled={submitDisabled}>
        {getUiText('add_materials')}
      </button>
    </FormControl>
  );
};
