import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Welcome from './Welcome';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import {useContent} from '../../utils/hooks/useContent';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(5),
  },
  callout: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}));

export default function WaitingRoom({handleBack, realName, roleName, isAdmin, lang}) {
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();
  const classes = useStyles();
  return (
    <>
      <Welcome
        roleName={roleName}
        welcome={getUiText('welcome', 'welcome', lang)}
        intro={getUiText('welcome_role', 'welcome_role', lang)}
      />
      <Box className={classes.callout} mt={5} p={2}>
        {isAdmin ? (
          <Typography align="center">
            You're the facilitator, <strong>{realName}</strong>. You'll need to start the simulation by choosing a
            company from the menu.
          </Typography>
        ) : (
          <Typography align="center">
            {getUiText('youll_be_admitted', 'youll_be_admitted', lang)}
            {realName && (
              <>
                {' '}
                <strong>{realName}</strong>.
              </>
            )}
          </Typography>
        )}
      </Box>
      <Button
        onClick={handleBack}
        className={classes.button}
        variant="outlined"
        startIcon={<ArrowBackIosOutlinedIcon />}
      >
        {getUiText('edit_name', 'edit_name', lang)}
      </Button>
    </>
  );
}
