import React, {useState, useContext, useEffect} from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {SocketContext} from '../../context/SocketContext';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import {Instructions} from './Instructions';
import {ControlledInput} from './Input';
import {Divider} from '@material-ui/core';
import {ReadOnlyNotice} from './ReadOnlyNotice';
import {useContent} from '../../utils/hooks/useContent';
import {useSocketChannel} from '../../utils/hooks/useSocketChannel';
import {permissionsKey} from '../../utils/constants';
import {RoleHighlighter} from '../../components/Highlighter';

export const Invoice = ({fields, handleFieldChange, disabled}) => {
  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const socketContext = useContext(SocketContext);
  const socket = socketContext.socket;

  // eslint-disable-next-line no-unused-vars
  const [paidInvoices] = useSocketChannel('paid invoices', socket);

  useEffect(() => {
    socketContext.socket.emit('get paid invoices');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(paidInvoices)) {
      const proxyEvent = {
        target: {
          id: `paidInvoices`,
          value: paidInvoices,
        },
      };
      handleFieldChange(proxyEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paidInvoices]);

  return (
    <>
      {disabled && <ReadOnlyNotice />}
      <Instructions text={getUiText('worksheet_instructions_invoice')} />
      {[0, 1, 2, 3, 4].map((el, index) => (
        <SingleInvoice
          index={index}
          id={`invoice-${el}`}
          fields={fields}
          handleFieldChange={handleFieldChange}
          socket={socket}
          fullFormDisabled={disabled}
          paidInvoices={Array.isArray(paidInvoices) ? paidInvoices : []}
        />
      ))}
    </>
  );
};

const SingleInvoice = ({index, fields, handleFieldChange, socket, paidInvoices, fullFormDisabled}) => {
  const [disabled, setDisabled] = useState(fullFormDisabled);
  const [billTo, setBillTo] = useState(fields?.invoices?.[index]?.billTo ?? 'Customer');

  const [invoiceId, setInvoiceId] = useState(fields?.invoices?.[index]?.id);
  const [invoiceHasBeenPaid, setInvoiceHasBeenPaid] = useState(paidInvoices?.includes(invoiceId));

  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const [invoiceData, setInvoiceData] = useState(fields?.invoices?.[index] ?? {});
  const [submittedInvoices, setSubmittedInvoices] = useState(fields.invoices ?? []);

  const [invoiceSubmitted, setInvoiceSubmitted] = useState(
    fields?.invoices?.filter((invoice) => invoice.id === invoiceId).length > 0
  );

  const handleChange = (e) => {
    setInvoiceData({...invoiceData, [e.target.id]: e.target.value});
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setBillTo(value);
  };

  const handleSubmit = () => {
    const id = `billTo-${billTo}-${Date.now()}`;
    socket.emit('invoice sent', {
      billTo: billTo,
      invoiceData: invoiceData,
      invoiceId: id,
      permissionRequired: billTo === 'Customer' ? permissionsKey.customer : permissionsKey.accountant,
    });
    setInvoiceId(id);

    const newSubmittedInvoices = [...submittedInvoices, {...invoiceData, billTo: billTo, id: id}];
    setSubmittedInvoices(newSubmittedInvoices);
    const proxyEvent = {
      target: {
        id: `invoices`,
        value: newSubmittedInvoices,
      },
    };
    handleFieldChange(proxyEvent);
    setInvoiceSubmitted(true);
    setDisabled(true);
  };

  useEffect(() => {
    if (invoiceSubmitted) {
      setDisabled(true);
    }
  }, [invoiceSubmitted]);

  useEffect(() => {
    setInvoiceHasBeenPaid(paidInvoices?.includes(invoiceId));
    if (invoiceHasBeenPaid) {
      setDisabled(true);
    }
  }, [paidInvoices, invoiceHasBeenPaid, invoiceId]);

  const areRequiredFieldsMissing = () => {
    if (!billTo) {
      return true;
    }
    if (!invoiceData.total) {
      return true;
    }
    return false;
  };

  return (
    <Box mb={4}>
      <Divider />
      <Box mb={2} mt={2} width={200}>
        <FormControl fullWidth>
          <InputLabel id="permission-required">{getUiText('bill_to')}:</InputLabel>
          <Select
            labelId="permission-select-label"
            id="billTo"
            value={billTo}
            onChange={handleSelectChange}
            disabled={disabled}
          >
            {['Customer', 'Accountant'].map((el) => (
              <MenuItem value={el}>
                <RoleHighlighter text={el} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-start" py={1}>
        <Box mr={3}>
          <ControlledInput
            id={`material`}
            type="text"
            value={invoiceData[`material`]}
            handleFieldChange={handleChange}
            label={getUiText('material')}
            disabled={disabled}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <ControlledInput
            id={`quantity`}
            type="number"
            value={`${invoiceData[`quantity`]}`}
            handleFieldChange={handleChange}
            label={getUiText('quantity')}
            disabled={disabled}
          />
          <Box px={1} flexGrow={1}>
            <Typography variant="body2" noWrap>
              x
            </Typography>
          </Box>
          <ControlledInput
            id={`cost`}
            type="number"
            value={`${invoiceData[`cost`]}`}
            handleFieldChange={handleChange}
            label={getUiText('unit_cost')}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            disabled={disabled}
          />
          <Box px={1}>=</Box>
          <ControlledInput
            id={`total`}
            type="number"
            value={`${invoiceData[`total`]}`}
            handleFieldChange={handleChange}
            label={getUiText('total')}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            disabled={disabled}
          />
        </Box>
      </Box>
      <Box mt={2} mb={2} align="right">
        <Box component="span" mr={3}>
          {invoiceSubmitted || invoiceHasBeenPaid ? (
            invoiceHasBeenPaid ? (
              <Chip size="small" color="primary" label={getUiText('invoice_paid')} />
            ) : (
              <Chip size="small" color="secondary" label={getUiText('not_paid')} />
            )
          ) : null}
        </Box>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled ? disabled : areRequiredFieldsMissing()}
          onClick={handleSubmit}
        >
          {invoiceSubmitted || invoiceHasBeenPaid ? getUiText('invoice_sent') : getUiText('send_invoice')}
        </Button>
      </Box>
      <Divider />
    </Box>
  );
};
