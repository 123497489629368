import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTheme} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

export const CustomCircularProgress = ({
  value,
  processTime,
  withLabel = false,
  size,
  thickness = 5,
  status = 'active',
  variant = 'determinate',
  backgroundColor = '#ECECEC',
}) => {
  const {palette} = useTheme();
  const [displayValue, setDisplayValue] = useState(value);

  // Avoid the rollback animation by removing the transition from nearly finished to just started
  // based on the process time.
  const noTransitionTime = processTime ? 100 / processTime : 0;
  const hideTransition = displayValue === 0;

  const strokeColor = palette?.[status]?.main;

  const useStyles = makeStyles(() => ({
    circle: {
      stroke: strokeColor,
      transitionDuration: 1000,
      // Linear timing function for linear progress.
      transitionTimingFunction: 'cubic-bezier(0.0, 0.0, 1.0, 1.0)',
    },
    circleNoTransition: {
      stroke: strokeColor,
      transition: 'unset',
    },
    hidden: {
      display: 'none',
    },
    bottom: {
      color: backgroundColor,
    },
  }));
  const classes = useStyles();

  // If the animation isn't quite at 100, speed it along
  // This makes the arrow out animation timing better, and gives us time to
  // reset to 0.
  useEffect(() => {
    if (value + noTransitionTime >= 100) {
      const timer = setTimeout(() => {
        setDisplayValue(100);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setDisplayValue(value);
    }
  }, [value, noTransitionTime]);

  // Once the timer gets to 100, reset it to 0 so the 0 -> first tick animation works.
  // Do not do this if the value is over 100, because that indicates
  // the worker is holding on to their finished work because the
  // communication square is full.
  useEffect(() => {
    if (displayValue === 100 && value <= 100) {
      const timer = setTimeout(() => {
        setDisplayValue(0);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [displayValue, value]);

  return (
    <Box position="relative">
      <CircularProgress
        variant="determinate"
        size={size}
        thickness={thickness}
        value={100}
        classes={{
          circleDeterminate: classes.bottom,
        }}
      />
      <CircularProgress
        variant={variant}
        value={displayValue}
        size={size}
        thickness={thickness}
        classes={{
          circleDeterminate: hideTransition ? classes.circleNoTransition : classes.circle,
          circleIndeterminate: status === 'active' ? classes.circle : classes.hidden,
        }}
        disableShrink={variant === 'indeterminate'}
        style={{
          position: 'absolute',
          left: 0,
        }}
      />
    </Box>
  );
};
