import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import {MinimalTabs, MinimalTab} from '../../components/Tabs';
import AlertHistory from '../alerts/AlertHistory';
import NewAlert from '../alerts/NewAlert';
import {FloorMap} from './Map';
import {ProductionActivity} from './ProductionActivity';
import {ProductionFlow} from './ProductionFlow';
import {useContent} from '../../utils/hooks/useContent';
import {AdminProductionFloorUtils} from './AdminProductionFloorUtils';

const backgroundColor = '#EEEEEE';
const productionFlow = 'production_flow';
const productionData = 'production_activity';
const floorMap = 'floor_map';
const alerts = 'alerts';

const tabs = [productionData, productionFlow, alerts];

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0px 20px',
    minHeight: '100%',
    backgroundColor: backgroundColor,
  },
  tabPanel: {
    paddingTop: 10,
    backgroundColor: backgroundColor,
  },
  heading: {
    color: '#000000',
    fontSize: 21,
    fontWeight: '400',
    marginBottom: 6,
  },
}));

export const ProductionTabs = () => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const handleChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <>
      <div className={classes.container}>
        <Box w={1} display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <Box display="flex" alignItems={'flex-end'}>
            <Typography component="h2" variant="h5" className={classes.heading}>
              {getUiText('production_floor')}
            </Typography>
            <AdminProductionFloorUtils />
          </Box>
          <MinimalTabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="OS Navigation"
            scrollButtons="on"
          >
            {tabs.map((label) => (
              <MinimalTab label={getUiText(label)} key={label} />
            ))}
          </MinimalTabs>
        </Box>
        <Box pb={5}>
          <div className={classes.tabPanel}>{getTabContent(tabIndex)}</div>
        </Box>
      </div>
    </>
  );
};

const getTabContent = (tabIndex) => {
  const tabLabel = tabs[tabIndex];
  switch (tabLabel) {
    case productionFlow:
      return (
        <>
          <NewAlert />
          <ProductionFlow />
        </>
      );
    case productionData:
      return (
        <>
          <NewAlert />
          <ProductionActivity />
        </>
      );
    case floorMap:
      return (
        <>
          <NewAlert />
          <FloorMap />
        </>
      );
    case alerts:
      return <AlertHistory />;
    default:
      break;
  }
};
