import React from 'react';
import {Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {RoleHighlighter} from '../../components/Highlighter';
import {InfoCard} from '../../components/InfoCard';
import {useContent} from '../../utils/hooks/useContent';
import {useChecklist} from '../../utils/hooks/useChecklist';

export const Responses = function ({responsesArray, selectedIndex}) {
  const [getFieldName, getUiText] = useContent();
  const [checked, handleChecklist] = useChecklist();
  const responses = responsesArray[selectedIndex]?.[`${getFieldName('prompts', 'response')}`];
  const additionalInfo = responsesArray[selectedIndex]?.[`${getFieldName('prompts', 'responseInfo')}`];
  const responseActionArray = responses?.split('-').filter(Boolean);

  return (
    <>
      {responses && (
        <>
          <Typography component="h3" variant="overline" gutterBottom>
            {getUiText('response')}
          </Typography>
          <Typography>
            <Box mb={2}>
              <RoleHighlighter text={responseActionArray[0]} />
            </Box>
            {responseActionArray.map((responseAction, index) => {
              const checkId = `response-action-${selectedIndex}-index-${index}`;
              return (
                index > 0 && (
                  <Box component="div" mb={2} key={checkId}>
                    <FormControlLabel
                      key={responseAction}
                      control={
                        <Checkbox
                          name={responseAction}
                          color="primary"
                          id={checkId}
                          checked={checked.includes(checkId)}
                          onChange={handleChecklist}
                        />
                      }
                      label={<RoleHighlighter text={responseAction} />}
                    />
                  </Box>
                )
              );
            })}
          </Typography>

          {additionalInfo && <InfoCard label={getUiText('additional_info')} bodyText={additionalInfo} />}
        </>
      )}
    </>
  );
};
