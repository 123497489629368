import {useState, useEffect} from 'react';

export function useSocketChannel(channel, socket) {
  const [data, setData] = useState({});

  useEffect(() => {
    let mounted = true;
    if (socket) {
      socket.on(channel, (data) => {
        if (mounted) {
          setData(data);
        }
      });
      return () => (mounted = false);
    }
  }, [socket, channel]);

  const start = () => {
    socket.emit(`set ${channel}`, {isActive: true});
  };

  const pause = () => {
    socket.emit(`set ${channel}`, {isActive: false});
  };

  return [data, start, pause];
}
