import React from 'react';
import {Button} from '@material-ui/core';

export const ResetNames = ({socket}) => {
  const handleClick = () => {
    socket.emit('reset names');
  };
  return (
    <Button button onClick={handleClick}>
      Reset Names
    </Button>
  );
};
