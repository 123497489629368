import React, {useContext} from 'react';
import {Typography} from '@material-ui/core';
import {CircularProgress} from '@material-ui/core';
import {UserContext} from '../../context/UserContext';
import {useApi} from '../../utils/hooks/useApi';
import {RoleHighlighter} from '../../components/Highlighter';
import {InfoCard} from '../../components/InfoCard';
import {useContent} from '../../utils/hooks/useContent';

const Goal = function () {
  const [getFieldName, getUiText] = useContent();

  const context = useContext(UserContext);
  const roleId = context.roleId;
  const companyId = context.companyId;
  const url = `/api/goals?${getFieldName('goals', 'role')}=${roleId}&${getFieldName('goals', 'company')}=${companyId}`;
  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);
  return (
    <>
      <Typography component="h3" variant="overline" gutterBottom>
        {getUiText('goal')}
      </Typography>
      {loading && <CircularProgress />}
      {loaded && result.length > 0 && (
        <div>
          <Typography gutterBottom>
            <RoleHighlighter text={result[0]?.[`${getFieldName('goals', 'title')}`]} />
          </Typography>
          <InfoCard
            label={getUiText('description')}
            bodyText={result[0]?.[`${getFieldName('goals', 'description')}`]}
          />
        </div>
      )}
    </>
  );
};
export default Goal;
