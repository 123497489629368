import React from 'react';
import {EPullImage} from './EPullImage';
import {NonEPullImage} from './NonEPullImage';

export const ModelAImage = ({hasDefects, isRed, hasSafetyWire, index, ePullSim = false, alertType, id}) => {
  return ePullSim ? (
    <EPullImage alertType={alertType} id={id} />
  ) : (
    <NonEPullImage hasDefects={hasDefects} hasSafetyWire={hasSafetyWire} isRed={isRed} index={index} id={id} />
  );
};
