import React from 'react';
import {PdfViewer} from './PdfViewer';
import {Gallery} from './Gallery';
import {useContent} from '../utils/hooks/useContent';

export const AirtableAttachments = function ({data}) {
  const [getFieldName] = useContent();

  const attachments = data[getFieldName('assets', 'srcArray')] ?? data[getFieldName('assets', 'srcArray', 'en')];
  const assetArray = attachments?.split(', ');
  // @todo: Filter by ends with .pdf. Currently no assets are being passed to the PDF.
  const pdfAttachments = assetArray.filter((attachment) => attachment?.type === 'application/pdf');
  const images = assetArray.filter((attachment) => attachment?.type !== 'application/pdf');
  return (
    <>
      {pdfAttachments.length > 0 && <PdfViewer url={pdfAttachments?.[0].url} />}
      {images.length > 0 && <Gallery airtableAttachments={images} alt={data[getFieldName('assets', 'title')]} />}
    </>
  );
};
