/* eslint-disable */
// https://usehooks.com/useOnScreen/
import {useState, useEffect} from 'react';

export const useOnScreen = (ref, rootMargin = '0px') => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      try {
        observer.observe(ref.current);
      } catch (err) {}
    }
    return () => {
      try {
        observer.unobserve(ref.current);
      } catch (err) {}
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
};
