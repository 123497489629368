import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ProductionRole from './station';
import {getMaterialQuantity, getMaterialColors, getMaterialColorsString} from '../../utils/helpers';
import {useContent} from '../../utils/hooks/useContent';
import Square from './station/Sqaure';
import {MaterialBin} from './MaterialBin';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
    color: '#737373',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  binsSquareName: {
    fontWeight: 500,
    fontSize: 12,
  },
  binQuantity: {
    fontWeight: 500,
    fontSize: 24,
    color: 'black',
  },
  squareWrapper: {
    background: 'white',
  },
  materialWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: 12,
    overflow: 'hidden',
  },
}));

const BinOrSquare = ({label, feederRoles, materialBaseName, materials, pullType, dashboard = false}) => {
  const classes = useStyles();

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  let quantity = getMaterialQuantity(materialBaseName, materials);
  // Include model A's that are in QA in the soldered boards milestone, per
  // Michael's feedback and discussion here: https://savaslabs.teamwork.com/#/messages/1083443?pmp=3686503.
  // Note, this only affects Push and New Push because the material base name is
  // `finalInspectionCommunicationSquare-modelA` in Pull and ePull. This is okay
  // though because for "pull" corps a square should only have 1 item on it and
  // it's easier to intuit from the header and this square if 1 is in QA and 1
  // is still sitting on the square.
  quantity = materialBaseName === 'modelA' ? quantity + getMaterialQuantity('inQAModelA', materials) : quantity;
  const materialColors = getMaterialColors(materialBaseName, materials);
  const materialsString = getMaterialColorsString(materialColors);

  return (
    <>
      {dashboard ? (
        <Paper variant="outlined" square>
          <Grid container>
            <Grid item xs={8}>
              <Box display="flex" flexDirection="column" overflow="hidden">
                {feederRoles?.map((roleName) => (
                  <ProductionRole channel={roleName} key={roleName} />
                ))}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box position="relative" w={1} height="100%">
                <ArrowRightAltIcon style={{position: 'absolute', left: -12, top: '36%'}} />
                {pullType && (
                  <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                    <Square filled={!!quantity} />
                  </Box>
                )}

                {!pullType && (
                  <Tooltip title={materialsString}>
                    <MaterialBin
                      label={`${getUiText(label)} ${getUiText('bin')}`}
                      quantity={quantity}
                      colorPositive={false}
                      colorZero={false}
                      uppercase={false}
                      wrapperClass={classes.materialWrapper}
                    />
                  </Tooltip>
                )}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      ) : pullType ? (
        <Box className={classes.squareWrapper}>
          <Square filled={!!quantity} />
        </Box>
      ) : (
        <MaterialBin label={getUiText(label)} quantity={quantity} colorPositive colorZero />
      )}
    </>
  );
};

export default BinOrSquare;
