import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Box, Typography} from '@material-ui/core';
import {useContent} from '../../../utils/hooks/useContent';
import {Bins} from '../Bins';

const useStyles = makeStyles(() => ({
  binContainer: {
    backgroundColor: '#EFEFEF',
    borderRadius: 65,
  },
  text: {
    lineHeight: 1.2,
  },
}));

const ProdFloorBinsOrSquare = ({style, type, label, binsSquares, pullType}) => {
  const classes = useStyles();
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  if (pullType) {
    return <Bins binsSquares={binsSquares} pullType={pullType} />;
  }

  return (
    <Box px={2} my={1} className={classes.binContainer} style={style}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
            <Typography variant="overline" className={classes.text} noWrap>
              {getUiText(type)}
            </Typography>
            <Typography variant="body1" className={classes.text} noWrap>
              {getUiText(label)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Bins binsSquares={binsSquares} pullType={pullType} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProdFloorBinsOrSquare;
