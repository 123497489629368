import React, {createContext} from 'react';

import {useSocket} from '../utils/hooks/useSocket';

export const SocketContext = createContext();

export const SocketProvider = (props) => {
  const socket = useSocket();

  return (
    <SocketContext.Provider
      value={{
        socket: socket,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
};
