import React, {useEffect, useState, useContext} from 'react';
import {useApi} from '../../utils/hooks/useApi';
import {Form} from './Form';
import {UserContext} from '../../context/UserContext';
import {useContent} from '../../utils/hooks/useContent';
import {formIds} from '../../utils/constants';

export const FormLoader = ({
  data,
  formIdProps = null,
  batchInfo = null,
  newAlert = false,
  batchReviewComplete = false,
  setBatchReviewComplete = null,
}) => {
  const [getFieldName] = useContent();

  const userContext = useContext(UserContext);
  const companyId = userContext.companyId;
  const simulationStartTime = userContext.simulationStartTime;
  const formName = data?.[`${getFieldName('assets', 'title')}`];

  // Disable the form based on roleId.
  const roleIdsWithEditAccess = data?.[`${getFieldName('assets', 'formEditRoleIds')}`];
  const userCanEditForm = roleIdsWithEditAccess?.includes(userContext.roleId);
  const disabled = !userCanEditForm;

  const formBaseId = formIdProps ? formIdProps : data?.[`${getFieldName('assets', 'uid')}`];

  // Store unique id per simulation run.
  let formId = `${formBaseId}-${companyId}-${simulationStartTime}`;

  if (formBaseId === formIds.invoice) {
    formId += userContext.roleId;
  }

  const [initialFieldState, setInitialFieldState] = useState();

  const url = `/api/forms?formId=${formId}`;

  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);

  // Overwrite default values with those from the DB on component load, if
  // stored values exist.
  useEffect(() => {
    if (result) {
      const storedData = result?.[0]?.data;
      delete storedData?.['formId'];
      setInitialFieldState({...storedData});
    }
    // Default values will not change, so it is left out of the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <>
      {initialFieldState && (
        <Form
          formId={formId}
          companyId={companyId}
          simulationStartTime={simulationStartTime}
          initialFieldState={initialFieldState}
          disabled={disabled}
          title={formName}
          batchInfo={batchInfo}
          newAlert={newAlert}
          batchReviewComplete={batchReviewComplete}
          setBatchReviewComplete={setBatchReviewComplete}
        />
      )}
    </>
  );
};
