import React, {createContext, useState, useEffect, useContext} from 'react';
import {useApi} from '../utils/hooks/useApi';
import {permissionsKey} from '../utils/constants';
import {useSocketChannel} from '../utils/hooks/useSocketChannel';
import {SocketContext} from './SocketContext';
import {useLocalStorage} from '../utils/hooks/useLocalStorage';
import {useContent} from '../utils/hooks/useContent';
import AccessControl from '../features/navigation/AccessControl';

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [getFieldName] = useContent();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const roleIdFromUrl = urlParams.get('role');
  const [companyId, setCompanyId] = useState();
  const [simulationStartTime, setSimulationStartTime] = useState();
  const [realName, setRealName] = useState('');
  const [roleName, setRoleName] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [language, setLanguage] = useLocalStorage('langCode');
  const socketContext = useContext(SocketContext);

  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(`/api/roles`);

  const paramMatchesRole =
    loaded && result.filter((role) => role[`${getFieldName('roles', 'uid')}`] === roleIdFromUrl).length > 0;

  const [localStorageRoleId, setLocalStorageRoleId] = useLocalStorage(
    'roleId',
    paramMatchesRole ? roleIdFromUrl : null
  );

  const roleId = roleIdFromUrl ? roleIdFromUrl : localStorageRoleId;
  // Update the role name on language change.
  useEffect(() => {
    const roleData = loaded && result.filter((role) => role[`${getFieldName('roles', 'uid')}`] === roleId)[0];

    const name = loaded && roleData?.[`${getFieldName('roles', 'roleName', language)}`];
    setRoleName(name);
  }, [result, loaded, language, getFieldName, roleId]);

  // Store the role Id so users can reenter the simulation without their specific URL "password".
  useEffect(() => {
    setLocalStorageRoleId(roleId);
  }, [roleId, setLocalStorageRoleId]);

  // Get permissions information
  useEffect(() => {
    // Determine if current user is an admin.
    const roleData = loaded && result.filter((role) => role[`${getFieldName('roles', 'uid')}`] === roleId)[0];
    const tempPermissions = loaded && roleData ? roleData?.[`${getFieldName('roles', 'permissions')}`] : [];
    setPermissions(tempPermissions);
    setIsAdmin(permissions?.includes(permissionsKey.admin));
  }, [loaded, result, getFieldName, permissions, roleId]);

  // Update the company id on websocket event.
  // eslint-disable-next-line no-unused-vars
  const [data, start, pause] = useSocketChannel('timer', socketContext.socket);
  useEffect(() => {
    if (data.companyId !== companyId) {
      setCompanyId(data.companyId);
    }
    if (data.startTimeStamp !== simulationStartTime) {
      setSimulationStartTime(data.startTimeStamp);
    }
  }, [data.companyId, companyId, data.startTimeStamp, simulationStartTime]);

  // Add an admin flag to the broswer tab. This is mainly to facilitate easier testing.
  useEffect(() => {
    if (isAdmin && !document.title.includes('(admin)')) {
      document.title += ' (admin)';
    }
  }, [isAdmin]);

  // Handle reset names.
  const [realNames] = useSocketChannel('names', socketContext.socket);
  useEffect(() => {
    if (realNames === 'reset') {
      setRealName('');
    }
  }, [realNames]);

  return (
    <UserContext.Provider
      value={{
        roleId: roleId,
        roleName: roleName,
        companyId: companyId,
        simulationStartTime: simulationStartTime,
        isAdmin: isAdmin,
        realName: realName,
        permissions: permissions,
        language: language,
        setRealName,
        setCompanyId,
        setLanguage,
      }}
    >
      {isAdmin && <AccessControl />}
      {props.children}
    </UserContext.Provider>
  );
};
