import React, {useEffect, useContext} from 'react';
import {Grid, Box} from '@material-ui/core';
import {SocketContext} from '../../context/SocketContext';
import {UserContext} from '../../context/UserContext';
import {useSocketChannel} from '../../utils/hooks/useSocketChannel';
import {Milestone} from './Milestone';
import {
  pushCompanyId,
  newPushCompanyId,
  newPushMilestones,
  ePullCompanyId,
  pullEPullMilestones,
} from '../../utils/constants';
import {getSimulationType} from '../../utils/helpers';
import {Graph} from './Graph';
import {Materials} from './Materials';

const pushMilestones = JSON.parse(JSON.stringify(newPushMilestones));
pushMilestones[0].binsSquares.splice(1, 1);
pushMilestones[0].binsSquares[0].materialBaseName = 'strippedWires';

export const ProductionActivity = () => {
  const socketContext = useContext(SocketContext);
  const userContext = useContext(UserContext);
  const [rawMaterials] = useSocketChannel('materials', socketContext.socket);
  const [inventory] = useSocketChannel('inventory', socketContext.socket);
  const currentCompanyId = userContext.companyId;
  let milestones = [];
  switch (currentCompanyId) {
    case pushCompanyId:
      milestones = pushMilestones;
      break;
    case newPushCompanyId:
      milestones = newPushMilestones;
      break;
    default:
      milestones = pullEPullMilestones;
      break;
  }

  const pullTypeSim = getSimulationType(currentCompanyId) === 'pullType';

  useEffect(() => {
    socketContext.socket.emit('get materials');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socketContext.socket.emit('get shipped model As');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1}>
      <Materials data={rawMaterials} pullTypeSim={pullTypeSim} materialsToDisplay={'raw_material'} type="raw" />
      {milestones.map((milestone, index) => (
        <Box clone key={`milestone-${index}`}>
          <Grid item xs={12} key={`milestone number ${index}`}>
            <Milestone data={milestone} materials={rawMaterials} pullTypeSim={pullTypeSim} index={index} />
          </Grid>
        </Box>
      ))}
      <Materials data={inventory} pullTypeSim={pullTypeSim} materialsToDisplay={'total_inventory'} type="total" />
      {pullTypeSim && <Graph itemSize={12} />}
    </Grid>
  );
};
