import {ControlledInput} from './Input';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Typography} from '@material-ui/core';

export const MultiplierInputs = ({
  fields,
  handleFieldChange,
  fieldId1,
  fieldId2,
  field1label,
  field2label,
  totalLabel = '',
  multiplier = 'x',
  disabled,
}) => {
  const getTotal = () => {
    const field1Value = parseFloat(fields[fieldId1]);
    const field2Value = parseFloat(fields[fieldId2]);
    if (field1Value >= 0 && field2Value >= 0) {
      let totalFloat;
      switch (multiplier) {
        case '@$10/hr  x':
          totalFloat = (field1Value * field2Value * 10) / 60;
          break;
        case '@$40/hr  x':
          totalFloat = (field1Value * field2Value * 40) / 60;
          break;
        default:
          totalFloat = field1Value * field2Value;
          break;
      }
      return totalFloat.toFixed(2);
    }
    return '';
  };
  return (
    <Box display="flex" alignItems="center">
      <ControlledInput
        id={fieldId1}
        type="number"
        value={`${fields[fieldId1]}`}
        handleFieldChange={handleFieldChange}
        label={field1label}
        disabled={disabled}
      />
      <Box px={1} flexGrow={1}>
        <Typography variant="body2" noWrap>
          {multiplier}
        </Typography>
      </Box>
      <ControlledInput
        id={fieldId2}
        type="number"
        value={`${fields[fieldId2]}`}
        handleFieldChange={handleFieldChange}
        label={field2label}
        InputProps={{
          startAdornment: <>{multiplier === 'x' && <InputAdornment position="start">$</InputAdornment>}</>,
        }}
        disabled={disabled}
      />
      <Box px={1}>=</Box>
      <TextField
        id={`${fieldId1}+${fieldId2}`}
        value={getTotal()}
        disabled
        label={totalLabel}
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </Box>
  );
};
