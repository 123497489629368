import React, {useEffect, useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTheme} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {Status} from '../../../components/Status';
import {CustomCircularProgress} from '../../../components/CustomCircularProgress';
import {Typography} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import InfoModal from './InfoModal';
import Arrow from './Arrow';
import {usePrevious} from '../../../utils/hooks/usePrevious';
import Pallet from './Pallet';
import {useSocketChannel} from '../../../utils/hooks/useSocketChannel';
import {SocketContext} from '../../../context/SocketContext';
import {getMaterialQuantity} from '../../../utils/helpers';
import {RoleHighlighter} from '../../../components/Highlighter';

const height = 60;
const progressWidth = 5;

const Station = ({
  title,
  status,
  workComplete,
  batchWipQty,
  usesPallet,
  videoUrl,
  loading = true,
  beforeArrowLong = false,
  afterArrowLong = false,
  notABot = false,
  activeIf,
  hideBeforeArrow,
  hideAfterArrow,
  style = {},
  channel,
  processTime,
}) => {
  const socketContext = useContext(SocketContext);
  const [materials] = useSocketChannel('materials', socketContext.socket);

  useEffect(() => {
    socketContext.socket.emit('get materials');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  const {palette} = useTheme();
  const prevWorkComplete = usePrevious(workComplete);
  const prevBatchWipQty = usePrevious(batchWipQty);
  let triggerBeforeArrow = false;
  let triggerAfterArrow = false;
  let triggerAfterPalletArrow = false;

  if (prevWorkComplete > workComplete && workComplete > 0) {
    triggerBeforeArrow = Date.now().toString();
  }

  if (prevWorkComplete === 0 && workComplete > 0) {
    triggerBeforeArrow = Date.now().toString();
  }

  if (prevWorkComplete >= 100 && workComplete < 100) {
    triggerAfterArrow = Date.now().toString();
  }

  if (batchWipQty < prevBatchWipQty) {
    triggerAfterPalletArrow = Date.now().toString();
  }

  if (notABot && activeIf) {
    const quantity = getMaterialQuantity(activeIf, materials);
    status = quantity > 0 ? 'active' : 'blocked';
  }

  let imageSrc = '';
  let image;

  // Remove number from the channel, so `solderer1` becomes `solderer`.
  const singularMachineName = channel.replace(/[0-9]/g, '').toString();

  try {
    image = require(`../../../assets/workerThumbnails/${singularMachineName}.png`);
  } catch (e) {
    // If the image does not exist, a generic avatar will display.
  }

  imageSrc = image?.default;

  const showCircularProgress = workComplete > 0 || status === 'active';

  return (
    <div className={classes.container} style={style}>
      {!hideBeforeArrow && <Arrow trigger={triggerBeforeArrow} long={beforeArrowLong} />}
      <Box my={1} className={classes.card} style={{background: loading ? '#EFEFEF' : palette?.[status]?.light}}>
        {!notABot && showCircularProgress && (
          <div className={classes.progress}>
            <CustomCircularProgress
              value={workComplete ? workComplete : 0}
              processTime={processTime}
              size={height + progressWidth * 4 - 2}
              thickness={progressWidth}
              status={status}
              backgroundColor="#838383"
            />
          </div>
        )}
        <Avatar alt={title} src={imageSrc} className={classes.avatar} />
        <div className={classes.body}>
          <Box display="flex" justifyContent="center">
            <Typography variant="body1" noWrap>
              {loading ? '' : <RoleHighlighter text={title} />}
            </Typography>
            {!loading && videoUrl && (
              <Box ml={1}>
                <InfoModal title={singularMachineName} videoUrl={videoUrl} />
              </Box>
            )}
          </Box>
          {!loading && <Status status={status} />}
        </div>
      </Box>

      {!hideAfterArrow && <Arrow trigger={triggerAfterArrow} long={afterArrowLong} />}
      {usesPallet && (
        <>
          <Pallet filled={batchWipQty} />
          <Arrow trigger={triggerAfterPalletArrow} />
        </>
      )}
    </div>
  );
};

export default Station;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-evenly',
  },
  card: {
    padding: 5,
    paddingLeft: height + 3 * progressWidth,
    paddingRight: height / 2 - 10,
    borderRadius: height / 2,
    display: 'flex',
    position: 'relative',
    height: height,
    zIndex: 1,
  },
  progress: {
    position: 'absolute',
    left: -2 * progressWidth + 1,
    top: -2 * progressWidth + 1,
  },
  bottom: {
    color: '#838383',
  },
  avatar: {
    position: 'absolute',
    left: 2,
    top: 2,
    height: height - 4,
    width: height - 4,
  },
  body: {
    textAlign: 'center',
    width: '100%',
  },
}));
