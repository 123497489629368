import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {useLocalStorage} from '../../utils/hooks/useLocalStorage';

// Faux authentication to act as a minor deterant for someone who incorrectly
// has a facilitator link.
const correctUsername = 'tatham';
const correctPassword = 'tatham20';

export default function AccessControl() {
  const [open, setOpen] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [saveLogin, setSaveLogin] = useState(false);

  const [username, setUsername] = useState('');
  const [pass, setPass] = useState('');

  const [storedUsername, setStoredUsername] = useLocalStorage('facilitator-user-name');
  const [storedPass, setStoredPass] = useLocalStorage('facilitator-pass');

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidUsername = username === correctUsername;
    const isValidPassword = pass === correctPassword;

    if (isValidUsername && isValidPassword) {
      setOpen(false);
      if (saveLogin) {
        setStoredUsername(username);
        setStoredPass(pass);
      }
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    const isValidStoredUsername = storedUsername === correctUsername;
    const isValidStoredPassword = storedPass === correctPassword;

    if (isValidStoredUsername && isValidStoredPassword) {
      setOpen(false);
    }
  }, [storedUsername, storedPass]);

  return (
    <div>
      <Dialog open={open} onClose={() => {}} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Facilitator Log In</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              You must log in to access the simulation as a facilitator. If you are not a facilitator, please reach out
              to the facilitator for a login link.
            </DialogContentText>
            {hasError && (
              <Box my={2}>
                <Alert severity="error">Login Failed: Your user ID or password is incorrect</Alert>
              </Box>
            )}

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  error={hasError}
                  autoFocus
                  id="username"
                  label="Username"
                  type="text"
                  fullWidth
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={hasError}
                  id="password"
                  label="Password"
                  type="password"
                  fullWidth
                  value={pass}
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={saveLogin}
                      onChange={() => setSaveLogin(!saveLogin)}
                      name="save-login"
                      color="primary"
                    />
                  }
                  label="Keep me logged in."
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} color="primary" variant="contained" type="submit">
              Log in
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
