import {useContext} from 'react';
import {Typography} from '@material-ui/core';
import {UserContext} from '../../context/UserContext';
import {useApi} from '../../utils/hooks/useApi';
import {Gallery} from '../../components/Gallery';
import {useContent} from '../../utils/hooks/useContent';

export const OrganizationTab = () => {
  const [getFieldName, getUiText] = useContent();

  const userContext = useContext(UserContext);
  const companyId = userContext.companyId;

  const url = `/api/companies?${getFieldName('companies', 'uid')}=${companyId}`;

  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);
  const orgChartAttachment = result?.[0]?.[`${getFieldName('companies', 'orgChart')}`];

  return (
    <>
      <Typography component="h3" variant="overline" gutterBottom>
        {getUiText('org_chart_and_role_resp')}
      </Typography>
      {orgChartAttachment && <Gallery airtableAttachments={[orgChartAttachment]} alt="Organization Chart" />}
    </>
  );
};
