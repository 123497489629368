import React, {createContext, useEffect, useState} from 'react';
import {useApi} from '../utils/hooks/useApi';

export const UiTextContext = createContext();

export const UiTextProvider = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(`/api/uiText`);
  const [translationKey, setTranslationKey] = useState({});

  useEffect(() => {
    let accumulator = {};
    result?.map((element) => {
      return (accumulator[element.location_key] = {en: element['Task (en)'], de: element['Task (de)']});
    });
    setTranslationKey(accumulator);
  }, [result, loaded]);

  return <UiTextContext.Provider value={{translationKey: translationKey}}>{props.children}</UiTextContext.Provider>;
};
