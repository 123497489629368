import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import WaitingRoom from './WaitingRoom';
import RoleNameForm from './RoleNameForm';
import {UserContext} from '../../context/UserContext';
import {SocketContext} from '../../context/SocketContext';
import {useSocketChannel} from '../../utils/hooks/useSocketChannel';
import {useContent} from '../../utils/hooks/useContent';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
}));

export default function Transitional() {
  const classes = useStyles();

  const userContext = useContext(UserContext);
  const roleName = userContext.roleName;
  const socketContext = useContext(SocketContext);

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();
  const steps = [
    getUiText('enter_name', 'enter_name', userContext.language),
    getUiText('waiting_room', 'waiting_room', userContext.language),
  ];

  // Get names on first render.
  useEffect(() => {
    if (socketContext.socket) {
      socketContext.socket.emit('get names');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketContext.socket]);

  // eslint-disable-next-line no-unused-vars
  const [data, start, pause] = useSocketChannel('names', socketContext.socket);

  // If the server sends a new real name matching the roleId, update the user real name.
  useEffect(() => {
    if (data[userContext.roleId]) {
      userContext.setRealName(data[userContext.roleId]);
    }
  }, [data, userContext]);

  // If the realnames have been reset by the admin, go back to the step of entering names.
  useEffect(() => {
    if (!userContext.realName) {
      setActiveStep(0);
    }
  }, [userContext.realName]);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <>
        {activeStep === 0 && (
          <RoleNameForm
            handleNext={handleNext}
            roleId={userContext.roleId}
            roleName={roleName}
            realName={userContext.realName}
            socket={socketContext.socket}
            lang={userContext.lang}
          />
        )}
        {activeStep === 1 && (
          <WaitingRoom
            handleBack={handleBack}
            roleName={roleName}
            realName={userContext.realName}
            isAdmin={userContext.isAdmin}
            lang={userContext.lang}
          />
        )}
        {activeStep === steps.length && (
          <>
            <Typography variant="h5" gutterBottom>
              You're in!
            </Typography>
            <Typography variant="subtitle1">You'll be taken to the simulation shortly.</Typography>
          </>
        )}
      </>
    </>
  );
}
