import React, {useContext, useEffect} from 'react';
import HighlightWords from 'react-highlight-words';
import {useSocketChannel} from '../utils/hooks/useSocketChannel';
import {SocketContext} from '../context/SocketContext';
import {useRoles} from '../utils/hooks/useRoles';
import {useContent} from '../utils/hooks/useContent';

const HighlightRoles = ({children, highlightIndex}) => {
  const [getFieldName] = useContent();

  const socketContext = useContext(SocketContext);
  const [roles] = useRoles();
  const roleId = roles
    ? roles.find((role) => role[`${getFieldName('roles', 'roleName')}`] === children)?.[
        `${getFieldName('roles', 'uid')}`
      ]
    : null;

  useEffect(() => {
    socketContext.socket.emit('get names');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [data, start, pause] = useSocketChannel('names', socketContext.socket);

  const realName = data?.[roleId];

  return (
    <strong className="highlighted-text">
      {children} {realName && <>({realName})</>}
    </strong>
  );
};

export const RoleHighlighter = function ({text = ''}) {
  const [roles] = useRoles();
  const [getFieldName] = useContent();
  return (
    <>
      {roles && (
        <HighlightWords
          searchWords={roles.map((role) => role[`${getFieldName('roles', 'roleName')}`])}
          textToHighlight={text}
          highlightTag={HighlightRoles}
        />
      )}
    </>
  );
};
