import {pullEntities} from './pull';

// ePull is identical to pull except in "what do they do" videos.
const ePullEntities = JSON.parse(JSON.stringify(pullEntities));

// Wirestripper
ePullEntities[9].videoUrl = 'https://vimeo.com/954824274';
// Staking Press Operator
ePullEntities[10].videoUrl = 'https://vimeo.com/954824258';
// Presolderer
ePullEntities[12].videoUrl = 'https://vimeo.com/954824194';
// Assembler
ePullEntities[14].videoUrl = 'https://vimeo.com/954824167';
ePullEntities[15].videoUrl = 'https://vimeo.com/954824167';
// Solderer
ePullEntities[18].videoUrl = 'https://vimeo.com/954824215';
ePullEntities[19].videoUrl = 'https://vimeo.com/954824215';

export default ePullEntities;
