import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import PhotoIcon from '@material-ui/icons/Photo';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {Time} from '../features/timer/Time';
import {useApi} from '../utils/hooks/useApi';
import {Video} from './Video';
import {Stopwatch} from './Stopwatch';
import {AirtableAttachments} from './AirtableAttachments';
import {FormLoader} from '../features/worksheets/FormLoader';
import {useContent} from '../utils/hooks/useContent';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  asset: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
}));

export const Asset = ({assetId, variant, presentationArea, setSelectedAsset, alertTime = null}) => {
  const [getFieldName] = useContent();
  const classes = useStyles();
  const url = `/api/assets?${getFieldName('assets', 'uid')}=${assetId}`;

  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);

  const assetPresentation = result?.[0]?.[getFieldName('assets', 'presentation')];
  const medium = result?.[0]?.[getFieldName('assets', 'medium')];
  const title = result?.[0]?.[getFieldName('assets', 'title')]
    ? result?.[0]?.[getFieldName('assets', 'title')]
    : result?.[0]?.[getFieldName('assets', 'fallbackTitle')];
  const uiElements = result?.[0]?.[getFieldName('assets', 'uiElements')];
  const showStopwatch = uiElements?.includes('Stopwatch');
  const videoUrl =
    result?.[0]?.[`${getFieldName('assets', 'videoSrc')}`] ??
    result?.[0]?.[`${getFieldName('assets', 'videoSrc', 'en')}`];

  const handleClick = () => {
    setSelectedAsset(assetId);
  };

  const handleBack = () => {
    setSelectedAsset(null);
  };

  const showInDocumentsTab = () => {
    return (
      variant === 'teaser' &&
      result?.length > 0 &&
      presentationArea === 'Reference' &&
      assetPresentation.includes('Reference')
    );
  };

  const showFullView = () => {
    return variant === 'full' && result?.length > 0;
  };

  return (
    <>
      {showInDocumentsTab() && (
        <ListItem component="a" button onClick={handleClick}>
          <ListItemIcon>
            {medium === 'Video' && <OndemandVideoIcon />}
            {medium === 'Form' && <AssignmentIcon />}
            {medium === 'Airtable Attachment' && <PhotoIcon />}
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      )}
      {presentationArea === 'Alert' && variant === 'teaser' && (
        <Box display="flex" alignItems="center">
          <Time totalSeconds={alertTime} variant="h6" />
          <Box component="span" p={1}>
            {' '}
          </Box>
          <Box component="span" display="inline-flex" alignItems="center">
            <Typography variant="subtitle1">{title}</Typography>
          </Box>
        </Box>
      )}
      {showFullView() && (
        <>
          {presentationArea === 'Reference' && (
            <div className={classes.header}>
              <Button color="primary" startIcon={<ArrowBackIosOutlinedIcon />} onClick={handleBack}>
                All Documents
              </Button>
              <Typography variant="subtitle2">{title}</Typography>
            </div>
          )}
          <div className={classes.asset}>
            {medium === 'Video' && <Video url={videoUrl} />}
            {medium === 'Airtable Attachment' && <AirtableAttachments data={result[0]} />}
            {medium === 'Form' && <FormLoader data={result[0]} />}
          </div>
          {showStopwatch && <Stopwatch />}
        </>
      )}
    </>
  );
};
