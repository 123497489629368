import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {withStyles} from '@material-ui/core/styles';

const indicatorColor = '#3f51b5';

export const BlockTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    width: '100%',
  },
  indicator: {
    backgroundColor: indicatorColor,
  },
})(Tabs);

export const BlockTab = withStyles((theme) => ({
  root: {
    minWidth: 72,
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const MinimalTabs = withStyles({
  root: {
    width: 'auto',
  },
  indicator: {
    backgroundColor: indicatorColor,
    bottom: 8,
  },
})(Tabs);

export const MinimalTab = withStyles((theme) => ({
  root: {
    minWidth: 'unset',
    textTransform: 'none',
    padding: 0,
    margin: '0 8px',
    color: '#979797',
    fontSize: 13,
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
