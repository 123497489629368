import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import {Typography} from '@material-ui/core';
import {RoleHighlighter} from './Highlighter';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 6,
    paddingBottom: 0,
  },
}));

export const InfoCard = ({label, bodyText, mt = 3}) => {
  const classes = useStyles();
  // Split body text on new line characters for formatting.
  const bodyTextArray = bodyText.split('\n');

  return (
    <Box component="div" mt={mt}>
      <Card variant="outlined">
        <CardContent className={classes.content}>
          <Typography component="h3" variant="overline">
            {label}
          </Typography>
          {bodyTextArray.map((p) => (
            <Typography component="p" variant="body1" color="textSecondary" gutterBottom>
              <RoleHighlighter text={p} />
            </Typography>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
};
