import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Box, Typography} from '@material-ui/core';
import {useContent} from '../../../utils/hooks/useContent';
import Square from './Sqaure';

const useStyles = makeStyles(() => ({
  binContainer: {
    backgroundColor: '#EFEFEF',
    borderRadius: 65,
    zIndex: 100,
    position: 'relative',
  },
  text: {
    lineHeight: 1.2,
  },
}));

const Pallet = ({filled = 0, batchQuantity = 4}) => {
  const classes = useStyles();
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const imageArray = [...Array(batchQuantity).keys()];

  if (batchQuantity === 1) {
    return (
      <Box textAlign="center">
        <Square filled={!!filled} />
      </Box>
    );
  }

  return (
    <Box my={2} px={2} className={classes.binContainer}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
            <Typography variant="overline" className={classes.text} noWrap>
              {getUiText('batching')}
            </Typography>
            <Typography variant="body1" className={classes.text} noWrap>
              {filled} of {batchQuantity}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" overflow="hidden" pt={0.5}>
            {imageArray.map((index) => (
              <Box pr={0.5} key={index}>
                <Square filled={index < filled} />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pallet;
