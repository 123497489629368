import React, {useState, useContext, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {getMaterialQuantity} from '../../utils/helpers';
import {ApproveRejectForm} from './ApproveRejectForm';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import IconButton from '@material-ui/core/IconButton';
import {UserContext} from '../../context/UserContext';
import {ePullCompanyId, pushCompanyId} from '../../utils/constants';
import {ModelAImage} from '../../components/ModelAImage';
import {SocketContext} from '../../context/SocketContext';
import {useSocketChannel} from '../../utils/hooks/useSocketChannel';

export const ApproveReject = ({
  alert,
  handleFieldChange,
  fields,
  newAlert = false,
  batchReviewComplete,
  setBatchReviewComplete,
}) => {
  const socketContext = useContext(SocketContext);
  const userContext = useContext(UserContext);
  const simulationStartTime = userContext.simulationStartTime;
  const alertType = alert.type;
  const materialName = alertType === 'modelACustomerBatchAlert' ? 'shippedModelA' : 'modelA';
  const batchSize = getMaterialQuantity(materialName, alert.colorBreakdown);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [highestEnabledIndex, setHighestEnabledIndex] = useState(newAlert ? 1 : batchSize);

  const handleBack = () => {
    const newIndex = currentIndex === 1 ? 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const handleForward = () => {
    const newIndex = currentIndex === batchSize ? batchSize : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  // Get an array of form indexes.
  const formIndexes = Array(batchSize)
    .fill()
    .map((x, i) => i + 1);

  const enableNext = () => {
    // Enable the next image. If all images have been reviewed,
    // Allow users to dismiss the alert.
    const newIndex = highestEnabledIndex + 1;
    if (newIndex > batchSize) {
      setBatchReviewComplete(true);
    }
    setHighestEnabledIndex(newIndex);
  };

  // Determine if the facilitator has set red wire or safety wires to appear in QA.
  const [displayRedModelAsData] = useSocketChannel('displayRedModelAs', socketContext.socket);
  const [displaySafetyWiresData] = useSocketChannel('displaySafetyWires', socketContext.socket);

  useEffect(() => {
    socketContext.socket.emit('get displayRedModelAs');
    socketContext.socket.emit('get displaySafetyWires');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // For the MVP, we are showing the red image as soon as the facilitator requests it.
  // However, the backend expects us to pass a flag for whether the model A is REALLY red,
  // so we need that data too.
  const isRed = currentIndex - alert.colorBreakdown.modelA > 0 || alert.colorBreakdown.shippedModelARed > 0;

  // Store the time in seconds that the first red batch (as determined by the isRedWireChangeOrderInitiated flag)
  // reaches the client. If the current alert seconds is equal to or greater than the stored seconds,
  // the batch is red.
  const redWireKey = `redWireInitatied-${simulationStartTime}-${userContext.roleId}`;
  const timeToDisplayRedModelAs = displayRedModelAsData?.displayRedModelAs;
  const storedRedWireTime = window.localStorage.getItem(redWireKey);
  if (!storedRedWireTime && timeToDisplayRedModelAs) {
    window.localStorage.setItem(redWireKey, timeToDisplayRedModelAs);
  }
  const showRedImage = storedRedWireTime ? alert.seconds >= storedRedWireTime : timeToDisplayRedModelAs;

  // Store the time in seconds that the first safety wire batch reaches client QA.
  // If the current alert seconds is equal to or greater than the stored seconds,
  // the batch has safety wires.
  const safetyWireKey = `safetyWire-${simulationStartTime}`;
  const timeToDisplaySafetyWireInQa = displaySafetyWiresData?.displaySafetyWires;
  const storedSafetyWireTime = window.localStorage.getItem(safetyWireKey);
  if (!storedSafetyWireTime && timeToDisplaySafetyWireInQa) {
    window.localStorage.setItem(safetyWireKey, timeToDisplaySafetyWireInQa);
  }
  const hasSafetyWire = storedSafetyWireTime ? alert.seconds >= storedSafetyWireTime : timeToDisplaySafetyWireInQa;

  return (
    <Paper square>
      <Grid container>
        <Grid item xs={12} lg={7}>
          <Image
            index={currentIndex}
            isRed={showRedImage}
            hasSafetyWire={hasSafetyWire}
            alertType={alertType}
            id={alert.id}
          />
          {batchSize > 1 && (
            <Stepper
              batchSize={batchSize}
              handleBack={handleBack}
              handleForward={handleForward}
              currentIndex={currentIndex}
              highestEnabledIndex={highestEnabledIndex}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={5}>
          <Box p={2}>
            {formIndexes.map((index) => (
              <div key={index}>
                {index === currentIndex && (
                  <ApproveRejectForm
                    id={`${alert.id}-${index}`}
                    enableNext={enableNext}
                    handleFieldChange={handleFieldChange}
                    fields={fields}
                    alertType={alertType}
                    isRed={isRed}
                    handleForward={newAlert ? handleForward : null}
                    batchReviewComplete={batchReviewComplete}
                  />
                )}
              </div>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const Stepper = ({batchSize, handleBack, handleForward, currentIndex, highestEnabledIndex}) => {
  return (
    <Box display="flex" justifyContent="space-evenly" alignItems="center">
      <IconButton disabled={currentIndex === 1} onClick={handleBack} color="primary">
        <ChevronLeftOutlinedIcon />
      </IconButton>
      <Typography variant="body1">
        {currentIndex} of {batchSize}
      </Typography>
      <IconButton disabled={currentIndex === highestEnabledIndex} onClick={handleForward} color="primary">
        <ChevronRightOutlinedIcon />
      </IconButton>
    </Box>
  );
};

const Image = ({index, isRed = false, hasSafetyWire = false, alertType, id}) => {
  const userContext = useContext(UserContext);
  const hasDefects = userContext.companyId === pushCompanyId;
  const ePullSim = userContext.companyId === ePullCompanyId;

  return (
    <ModelAImage
      hasDefects={hasDefects}
      isRed={isRed}
      hasSafetyWire={hasSafetyWire}
      index={index}
      ePullSim={ePullSim}
      alertType={alertType}
      id={id}
    />
  );
};
