import {useState, useContext, useEffect} from 'react';
import {UserContext} from '../../context/UserContext';
import {useApi} from './useApi';
import {getTwoDigits} from '../helpers';
import {useContent} from './useContent';

export const useOs = () => {
  const [getFieldName] = useContent();

  const userContext = useContext(UserContext);
  const roleId = userContext.roleId;
  const companyId = userContext.companyId;

  const [actions, setActions] = useState();
  const [assets, setAssets] = useState();
  const [responses, setResponses] = useState();

  const url = `/api/prompts?${getFieldName('prompts', 'role')}=${roleId}&${getFieldName(
    'prompts',
    'company'
  )}=${companyId}`;

  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);

  const refreshOsData = (result) => {
    const allPrompts = result.sort((a, b) =>
      getTwoDigits(a[`${getFieldName('prompts', 'minute')}`]) > getTwoDigits(b[`${getFieldName('prompts', 'minute')}`])
        ? 1
        : -1
    );
    let assetArray = [];
    let responsesArray = [];
    let scriptArray = [];
    allPrompts.map((el) => (el?.[`${getFieldName('prompts', 'action')}`] ? scriptArray.push(el) : null));
    allPrompts.map((el) => {
      // Collect all assets referenced by prompts.
      assetArray = el?.[[`${getFieldName('prompts', 'assets')}`]]
        ? assetArray.concat(el?.[[`${getFieldName('prompts', 'assets')}`]])
        : assetArray;
      // Remove duplicates.
      assetArray = [...new Set(assetArray)];
      return el;
    });

    allPrompts.map((el) => (el?.[`${getFieldName('prompts', 'response')}`] ? responsesArray.push(el) : null));
    setAssets(assetArray);
    setResponses(responsesArray);
    setActions(scriptArray);
  };

  useEffect(() => {
    if (!actions) {
      if (loaded && result) {
        refreshOsData(result);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, loaded, result]);

  useEffect(() => {
    if (result) {
      refreshOsData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, result]);

  return [assets, responses, actions];
};
