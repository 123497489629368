import React from 'react';
import Typography from '@material-ui/core/Typography';
import {getTwoDigits} from '../../utils/helpers';

export const Time = ({totalSeconds, variant = 'h3', className = ''}) => {
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return (
    <Typography nowrap="true" variant={variant} display="inline" className={className}>
      {hours > 0 && <>{hours}:</>}
      {minutes}:{getTwoDigits(seconds)}
    </Typography>
  );
};
