import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import Tooltip from '@material-ui/core/Tooltip';

export const TimerControls = ({isActive, pause, start}) => {
  return (
    <>
      {isActive ? (
        <Tooltip arrow title="Pause">
          <IconButton onClick={pause} aria-label="pause timer" color="inherit">
            <PauseCircleFilledIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip arrow title="Start">
          <IconButton onClick={start} aria-label="start timer" color="inherit">
            <PlayCircleFilledWhiteIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
