import {useState, useEffect} from 'react';
import {useApi} from './useApi';

export const useRoles = () => {
  const [roles, setRoles] = useState();
  const url = `/api/roles`;

  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);

  useEffect(() => {
    if (!roles && loaded) {
      setRoles(result);
    }
  }, [result, loaded, roles]);

  return [roles];
};
