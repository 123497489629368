import React, {useEffect, useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import Box from '@material-ui/core/Box';
import {ReactComponent as ArrowSvg} from '../../../assets/icons/arrow.svg';
import {ReactComponent as ArrowLongSvg} from '../../../assets/icons/arrow-long.svg';
import {ReactComponent as Circle} from '../../../assets/icons/circle.svg';

const Arrow = ({trigger = false, long = false}) => {
  const initialY = long ? -130 : -30;
  const finalY = long ? 130 : 60;

  const [y, setY] = useState(initialY);
  const [isVisible, setIsVisible] = useState(false);

  const doAnimate = () => {
    setY(finalY);
    setIsVisible(true);
  };

  // Run the animation if the trigger updates
  useEffect(() => {
    if (trigger) {
      doAnimate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // Reset.
  useEffect(() => {
    if (y === finalY) {
      setTimeout(() => {
        setY(0);
        setIsVisible(false);
      }, 2000);
    }
  }, [y, finalY]);

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      position="relative"
    >
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{y: initialY, opacity: 0.8, position: 'absolute'}}
            animate={{
              y: y,
              opacity: 1,
              position: 'absolute',
            }}
            transition={{
              type: 'spring',
              stiffness: 260,
              damping: 200,
            }}
          >
            <Circle />
          </motion.div>
        )}
      </AnimatePresence>

      {long ? <ArrowLongSvg /> : <ArrowSvg />}
    </Box>
  );
};

export default Arrow;
