import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import {AdminMenuItems} from './MenuItems';
import Roles from '../../components/Roles';
import CompanyReset from './CompanyReset';
import {AlertTrigger} from '../alerts/AlertTrigger';

export default function AdminMenu() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleOpen = () => {
    setDrawerOpen(true);
  };

  const [adminPane, setAdminPane] = useState();

  return (
    <>
      <Tooltip title="facilitator tools">
        <IconButton onClick={handleOpen} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
        <AdminMenuItems setPane={setAdminPane} />
        <Divider />
        <Box m={2}>
          {adminPane === 'Roles' && <Roles links />}
          {adminPane === 'Company Reset' && <CompanyReset />}
          {adminPane === 'Alert Trigger' && <AlertTrigger />}
        </Box>
      </Drawer>
    </>
  );
}
