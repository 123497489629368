import React from 'react';
import ReactMarkdown from 'react-markdown';
import {Typography} from '@material-ui/core';
import {useContent} from '../../utils/hooks/useContent';

export const Home = function () {
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  return (
    <>
      <Typography component="h3" variant="overline" gutterBottom>
        {getUiText('instructions')}
      </Typography>
      <Typography component="div">
        <ReactMarkdown>{getUiText('instructions_content')}</ReactMarkdown>
      </Typography>
    </>
  );
};
