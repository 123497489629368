import {useState, useContext, useEffect} from 'react';
import {AlertContext} from '../../context/AlertContext';
import {useApi} from '../../utils/hooks/useApi';
import {useContent} from './useContent';

export function useTimeBasedAlert(assetIds, seconds) {
  const [getFieldName] = useContent();

  const alertContext = useContext(AlertContext);
  const [alertAsset, setAlertAsset] = useState();

  // Fetch asset data associated with the prompt.
  const queryArray = assetIds?.map((assetId) => `${getFieldName('assets', 'uid')}=${assetId}`);
  const query = queryArray?.join('&');
  const url = `/api/assets?${query}`;

  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);

  // Get only "Alert" type assets.
  useEffect(() => {
    if (loaded && result) {
      const tempAlertAssets = result.filter(
        (asset) => asset[`${getFieldName('assets', 'presentation')}`][0] === 'Alert'
      );
      setAlertAsset(tempAlertAssets?.[0]);
    }
  }, [loaded, result, getFieldName]);

  // Trigger the alert.
  useEffect(() => {
    if (alertAsset) {
      const uid = alertAsset[`${getFieldName('assets', 'uid')}`];
      // Both id and assetID are the same in order to prevent time-based alerts
      // from re-displaying if a user navigates back in time to a script minute
      // with an associated alert.
      alertContext.addAlert({id: uid, assetId: uid, seconds: seconds});
    }
    // Intentionally run this effect only on alertAsset change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertAsset, getFieldName]);
}
