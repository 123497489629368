import React, {useContext} from 'react';
import useDimensions from 'react-cool-dimensions';
import {makeStyles} from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Company from '../../components/Company';
import UserInfo from '../../features/os_book/UserInfo';
import {Timer} from '../timer/Timer';
import AdminMenu from './AdminMenu';
import {UserContext} from '../../context/UserContext';
import {TimeContext} from '../../context/TimeContext';
import {Status} from '../production_floor/Status';
import {useContent} from '../../utils/hooks/useContent';

const useStyles = makeStyles((theme) => ({
  company: {
    justifyContent: 'space-between',
    height: 120,
    boxShadow: '0px 4px 8px #00000025',
  },
  appBar: {
    boxShadow: 'none',
    position: 'inherit',
  },
  os: {
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    color: 'black',
    height: 72,
  },
  icon: {
    marginRight: 24,
  },
  chip: {
    color: 'white',
    borderColor: 'white',
    textTransform: 'capitalize',
    borderWidth: 0.5,
    borderRadius: 5,
  },
}));

export default function AppBar({variant}) {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const userContext = useContext(UserContext);
  const timeContext = useContext(TimeContext);
  const isAdmin = userContext.isAdmin;

  // Observe the container size at specifict breakpoints.
  // eslint-disable-next-line no-unused-vars
  const {observe, currentBreakpoint} = useDimensions({
    breakpoints: {XS: 0, MD: 480},
    // Will only update the state on breakpoint changed, default is false
    updateOnBreakpointChange: true,
  });

  return (
    <MuiAppBar className={classes.appBar}>
      {variant === 'OS' && (
        <Toolbar className={classes.os}>
          <UserInfo />
        </Toolbar>
      )}
      {variant === 'company' && (
        <Toolbar className={classes.company} ref={observe}>
          <Box display="flex" flexDirection="column" justifyContent="start">
            <Company />
            <Chip
              className={classes.chip}
              variant="outlined"
              size="small"
              label={
                <>
                  {getUiText('simulation')}{' '}
                  {timeContext.isTimerRunning ? <>{getUiText('active')}</> : <>{getUiText('paused')}</>}
                </>
              }
            />
          </Box>
          <Status vertical={currentBreakpoint === 'XS'} />
          <Timer isAdmin={isAdmin} small={currentBreakpoint === 'XS'} />
          {isAdmin && <AdminMenu />}
        </Toolbar>
      )}
    </MuiAppBar>
  );
}
