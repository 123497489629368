import {useState} from 'react';

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      const newValue = event.target.value ? event.target.value : event.target.checked ? event.target.checked : '';
      setValues({
        ...fields,
        [event.target.id]: newValue,
      });
    },
  ];
}
