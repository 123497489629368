import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';

export const MenuItem = ({label, setPane, icon}) => {
  const handleClick = () => {
    setPane(label);
  };
  return (
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        {icon === 'dashboard' && <DashboardOutlinedIcon />}
        {icon === 'build' && <BuildOutlinedIcon />}
        {icon === 'people' && <PeopleOutlinedIcon />}
        {icon === 'transportation' && <BusinessOutlinedIcon />}
        {icon === 'alert' && <NotificationImportantOutlinedIcon />}
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};
