import React, {useContext, useEffect, useState} from 'react';
import ImageGallery from 'react-image-gallery';
import {useApi} from '../utils/hooks/useApi';
import {UserContext} from '../context/UserContext';
import {formIds, ePullCompanyId} from '../utils/constants';
import {useLocalStorage} from '../utils/hooks/useLocalStorage';

export const EPullImage = ({alertType, id}) => {
  const userContext = useContext(UserContext);
  const companyId = ePullCompanyId;
  const simulationStartTime = userContext.simulationStartTime;
  const formId = `${formIds.order}-${companyId}-${simulationStartTime}`;

  const [variantIdMap, setVariantIdMap] = useLocalStorage(`${alertType}-${simulationStartTime}`, {});

  // Get the full order list from the DB.
  const [orders, setOrders] = useState([]);

  const url = `/api/forms?formId=${formId}`;

  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);

  // Overwrite default values with those from the DB on component load, if
  // stored values exist.
  useEffect(() => {
    if (result) {
      const storedOrders = result?.[0]?.data?.orders;
      setOrders(storedOrders);
    }
    // Default values will not change, so it is left out of the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  // Add the alertId variant pair to local storage.
  // If the alertId isn't stored yet, store it to add to the "queue" of
  // reviewed
  // @todo: If QA rejects a Model A (which shouldn't happen in ePull) the customer
  // queue will be off.
  // @todo: If there are multiple people reviewing model As (different browswers),
  // their queues will be wrong since we're storing in localStorage.
  useEffect(() => {
    if (!variantIdMap[id]) {
      const index = Object.keys(variantIdMap).length - 1 ?? 0;
      variantIdMap[id] = orders[index];
      setVariantIdMap(variantIdMap);
    }
  }, [orders, variantIdMap, id, setVariantIdMap]);

  const currentOrderVariant = variantIdMap[id];
  let items = [];

  if (currentOrderVariant) {
    const image = require(`../assets/modelAPics/ePull/${currentOrderVariant}.jpg`);
    items = [
      {
        original: image.default,
        originalAlt: `Model A - variant ${currentOrderVariant}`,
        originalTitle: currentOrderVariant,
        description: currentOrderVariant,
      },
    ];
  }

  return currentOrderVariant ? (
    <ImageGallery items={items} showPlayButton={false} showIndex={false} showThumbnails={false} />
  ) : (
    <p>{`Something went wrong displaying an ePull image of variant ${currentOrderVariant}`}</p>
  );
};
