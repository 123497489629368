import React, {useEffect, useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Divider} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {SocketContext} from '../../context/SocketContext';
import {useSocketChannel} from '../../utils/hooks/useSocketChannel';
import {getMaterialQuantity} from '../../utils/helpers';
import {useContent} from '../../utils/hooks/useContent';

const useStyles = makeStyles(() => ({
  divider: {
    background: '#FFFFFF50',
  },
  statLabel: {
    fontSize: 13,
    textTransform: 'capitalize',
  },
  statNumber: {
    fontWeight: 600,
    fontSize: 36,
  },
}));

export const Status = ({vertical = false}) => {
  const classes = useStyles();
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const socketContext = useContext(SocketContext);
  // eslint-disable-next-line no-unused-vars
  const [data, start, pause] = useSocketChannel('materials', socketContext.socket);

  useEffect(() => {
    socketContext.socket.emit('get materials');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modelAQuantity = getMaterialQuantity('modelA', data);
  const modelAInQaQuantity = getMaterialQuantity('inQAModelA', data);
  const inQa = modelAQuantity + modelAInQaQuantity;
  const approvedModelA = getMaterialQuantity('approvedModelA', data);
  const shippedModelA = getMaterialQuantity('shippedModelA', data);
  const inCustomerReview = getMaterialQuantity('inCustomerQAModelA', data);
  const inTransit = approvedModelA + shippedModelA + inCustomerReview;
  const approved = getMaterialQuantity('customerAcceptedModelA', data);
  const discarded = getMaterialQuantity('discardedModelA', data);
  const inRework = getMaterialQuantity('inReworkModelA', data);
  const customerRejected = getMaterialQuantity('customerRejectedModelA', data);
  const rejected = discarded + inRework + customerRejected;

  const items = [
    {label: getUiText('in_qa'), quantity: inQa},
    {label: getUiText('transit'), quantity: inTransit},
    {label: getUiText('accepted'), quantity: approved},
    {label: getUiText('rejected'), quantity: rejected},
  ];

  return (
    <Box display="flex" justifyContent="space-evenly">
      <Divider orientation="vertical" flexItem variant="middle" className={classes.divider} />
      <Grid container direction="row" spacing={vertical ? 1 : 2}>
        {items.map((item) => (
          <Grid key={item.label} item xs={vertical ? 6 : 3}>
            <Box
              display="flex"
              flexDirection={vertical ? 'row' : 'column'}
              justifyContent={vertical ? 'space-between' : 'center'}
              alignItems="center"
            >
              <Typography variant="caption" noWrap className={classes.statLabel}>
                {item.label}
              </Typography>
              <Typography variant={vertical ? 'body1' : 'h4'} className={classes.statNumber}>
                {item.quantity}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem className={classes.divider} />
    </Box>
  );
};
