import React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import {MenuItem} from './MenuItem';

export const AdminMenuItems = ({setPane}) => {
  return (
    <div>
      <ListSubheader inset>Facilitator Tools</ListSubheader>
      <MenuItem label="Roles" setPane={setPane} icon="people" />
      <MenuItem label="Company Reset" setPane={setPane} icon="transportation" />
      <MenuItem label="Alert Trigger" setPane={setPane} icon="alert" />
    </div>
  );
};
