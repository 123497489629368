import React, {useState, useContext, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import {SocketContext} from '../../context/SocketContext';
import {InfoPopUp} from '../../components/InfoPopUp';
import {useContent} from '../../utils/hooks/useContent';

export const ApproveRejectForm = ({
  id,
  enableNext,
  handleFieldChange,
  fields,
  alertType = 'modelAQABatchAlert',
  isRed = true,
  handleForward = null,
  batchReviewComplete,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const defects = [
    {title: getUiText('exposed_copper'), id: 'exposed-copper'},
    {title: getUiText('melted_insulation'), id: 'melted-insulation'},
    {title: getUiText('discolored_terminal_tops'), id: 'discolored-terminal-tops'},
    {title: getUiText('insulation_spacing'), id: 'insulation-spacing'},
  ];

  const socketContext = useContext(SocketContext);
  const socket = socketContext.socket;

  const [status, setStatus] = useState(fields[`${id}-approveOrReject`]);
  const [isFormDisabled, setIsFormDisabled] = useState(fields[`${id}-submitted`] ? fields[`${id}-submitted`] : false);

  const approveLabel = alertType === 'modelAQABatchAlert' ? getUiText('pass_and_ship') : getUiText('accept');
  const rejectLabel = alertType === 'modelAQABatchAlert' ? getUiText('reject_for_rework') : getUiText('reject');

  const handleRadioChange = (e) => {
    // The useFormFields expects id value object keys.
    // Format the radio event to be used by the useFormFields hook.
    const proxyEvent = {
      target: {
        id: `${id}-approveOrReject`,
        value: e.target.value,
      },
    };
    handleFieldChange(proxyEvent);
    setStatus(e.target.value);
  };

  const handleDefectChange = (e) => {
    handleFieldChange(e);
  };

  const areDefectsEntered = () => {
    const fieldKeys = Object.keys(fields);
    const fieldsInBatch = fieldKeys.filter((key) => key.includes(id));
    const defects = fieldsInBatch.filter((key) => key.includes('-defect-'));
    let result = false;
    defects.forEach((defectKey) => {
      if (fields[defectKey] > 0) {
        result = true;
      }
    });
    return result;
  };

  const enableRejectButton = () => {
    if (status !== 'reject') {
      return false;
    }
    const rejectsEntered = areDefectsEntered();
    return rejectsEntered;
  };

  const handleApprove = () => {
    // @todo: update to handle customer review
    socket.emit('approve or reject', {isApproved: true, isRed: isRed, userRoleQa: alertType === 'modelAQABatchAlert'});
    handleSubmit();
  };

  const handleReject = () => {
    // @todo: update to handle customer review
    socket.emit('approve or reject', {isApproved: false, isRed: isRed, userRoleQa: alertType === 'modelAQABatchAlert'});
    handleSubmit();
  };

  const handleSubmit = () => {
    const proxyEvent = {
      target: {
        id: `${id}-submitted`,
        value: true,
      },
    };
    handleFieldChange(proxyEvent);
    enableNext();
    setIsFormDisabled(true);
  };

  useEffect(() => {
    if (isFormDisabled) {
      enableNext();
    }
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {alertType === 'modelAQABatchAlert' && <InfoPopUp content={getUiText('qa_form_instructions')} />}
      <form id={id}>
        <FormControl component="fieldset" disabled={isFormDisabled}>
          <FormLabel component="legend">{getUiText('product_quality_specifications')}</FormLabel>
          <RadioGroup
            aria-label="Product quality/specifications"
            name={`${id}-${status}`}
            value={fields[`${id}-approveOrReject`]}
            onChange={handleRadioChange}
            id={`${id}-${status}`}
          >
            <Box component="span" display="flex" flexWrap>
              <FormControlLabel value="approve" control={<Radio />} label={approveLabel} />
              <FormControlLabel value="reject" control={<Radio />} label={rejectLabel} />
            </Box>
          </RadioGroup>
        </FormControl>
        <Box pt={3} pb={3}>
          {status === 'reject' && (
            <>
              <FormLabel component="legend">{getUiText('defects')}</FormLabel>
              {defects.map((defect) => (
                <FormControl fullWidth hiddenLabel disabled={isFormDisabled} key={defect.id}>
                  <Box component="span" display="flex" justifyContent="space-between" alignItems="flex-end" mb={1}>
                    <Typography variant="body1">{defect.title}</Typography>
                    <Input
                      id={`${id}-defect-${defect.id}`}
                      type="number"
                      value={fields[`${id}-defect-${defect.id}`] ? fields[`${id}-defect-${defect.id}`] : 0}
                      onChange={handleDefectChange}
                      inputProps={{min: 0, max: 20}}
                      aria-label={defect.title}
                    />
                  </Box>
                </FormControl>
              ))}
            </>
          )}
        </Box>
        <Box>
          {isFormDisabled ? (
            <>
              {handleForward && !batchReviewComplete ? (
                <Box mt={2}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleForward}
                    endIcon={<NavigateNextOutlinedIcon />}
                  >
                    {getUiText('inspect_next_model_a')}
                  </Button>
                </Box>
              ) : (
                <Typography variant="body2" color="disabled">
                  {getUiText('submitted_successfully')}
                </Typography>
              )}
            </>
          ) : (
            <>
              {status !== 'reject' && (
                <Button variant="contained" color="primary" disabled={!Boolean(status)} onClick={handleApprove}>
                  {status ? status : 'submit'}
                </Button>
              )}
              {status === 'reject' && (
                <Button variant="contained" color="secondary" disabled={!enableRejectButton()} onClick={handleReject}>
                  {status}
                </Button>
              )}
            </>
          )}
        </Box>
      </form>
    </>
  );
};
