import React, {createContext, useState, useContext, useEffect} from 'react';
import {useSocketChannel} from '../utils/hooks/useSocketChannel';
import {TimeContext} from './TimeContext';
import {UserContext} from './UserContext';
import {SocketContext} from './SocketContext';
import {useLocalStorage} from '../utils/hooks/useLocalStorage';
import {permissionsKey, modelAAlertTypes} from '../utils/constants';

export const AlertContext = createContext();

export const AlertProvider = (props) => {
  const timeContext = useContext(TimeContext);
  const userContext = useContext(UserContext);
  const socketContext = useContext(SocketContext);

  const [hasNewAlert, setHasNewAlert] = useState(false);
  // Flag for closing modals to go to alert.
  const [goToNewAlert, setGoToNewAlert] = useState(false);
  const [showNewAlertWarning, setShowNewAlertWarning] = useState(false);

  // Get stored alerts from local storage if they exist. This handles the
  // case of a user reloading their broswer during a simulation.
  // @todo: Ideally, alerts would be stored in the database per user role.
  const [storedAlerts, setStoredAlerts] = useLocalStorage(`storedAlerts-${userContext.roleId}`, '');
  const [alerts, setAlerts] = useState(storedAlerts ? storedAlerts : []);

  // Determine if a user is actively reviewing model As.
  const isCurrentAlertQA = () => {
    if (!hasNewAlert) {
      return false;
    }
    if (alerts?.length < 1) {
      return false;
    }

    const currentAlertType = alerts?.[0]?.type;
    if (isAlertTypeAModelABatch(currentAlertType)) {
      return true;
    }
    return false;
  };

  const isAlertTypeAModelABatch = (alertType) => {
    return alertType === modelAAlertTypes.qa || alertType === modelAAlertTypes.customer;
  };

  // Add alert to front of the stack.
  const addAlert = (newAlert) => {
    if (userCanSeeAlert(newAlert.type, newAlert.permissionRequired, userContext.permissions)) {
      const newUniqueAlert = alerts?.filter((alert) => alert.id === newAlert.id).length <= 0;
      if (newUniqueAlert) {
        // If the user is currently reviewing model As, that takes priority over all other alerts.
        // Send other alerts to alert history without displaying them as new alerts.
        if (isCurrentAlertQA() && !isAlertTypeAModelABatch(newAlert.type)) {
          alerts?.splice(1, 0, newAlert);
          setAlerts(alerts);
          setStoredAlerts(alerts);
          // @todo Ideally we'd queue up waiting alerts and display them as long as a QA or customer
          // review alert didn't come up.
        } else {
          // If the current alert is not the special model A review alert type,
          // bump the current alert to history and display the new alert.
          alerts.unshift(newAlert);
          setAlerts(alerts);
          setHasNewAlert(true);
          setStoredAlerts(alerts);
        }
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [data, start, pause] = useSocketChannel('alerts', socketContext.socket);

  // When there is a new alert emitted on the 'alerts' channel, update context.
  useEffect(() => {
    const uniqueAlert = alerts?.filter((alert) => alert.id === data.id).length <= 0;
    if (uniqueAlert) {
      addAlert(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data.id]);

  // Clear alerts if time is 0.
  useEffect(() => {
    if (timeContext.seconds === 0) {
      setAlerts([]);
      setHasNewAlert(false);
      setStoredAlerts([]);
    }
    // Do not run on setStoredAlerts.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeContext]);

  // Prevent fantom alert notification on start up.
  useEffect(() => {
    if (!alerts || alerts?.length === 0) {
      setHasNewAlert(false);
    }
  }, [alerts]);

  return (
    <AlertContext.Provider
      value={{
        alerts: alerts.filter((alert) => !isEmpty(alert)),
        setAlerts,
        addAlert,
        hasNewAlert,
        setHasNewAlert,
        goToNewAlert,
        setGoToNewAlert,
        showNewAlertWarning,
        setShowNewAlertWarning,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

const userCanSeeAlert = (alertType, permissionRequired = null, userPermissions) => {
  switch (alertType) {
    case modelAAlertTypes.qa:
      return userPermissions?.includes(permissionsKey.qa);
    case modelAAlertTypes.customer:
      return userPermissions?.includes(permissionsKey.customer);
    default:
      break;
  }
  if (permissionRequired && permissionRequired !== permissionsKey.all) {
    return userPermissions?.includes(permissionRequired);
  }
  return true;
};

const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};
