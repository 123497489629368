import {useState, useEffect} from 'react';
import {io} from 'socket.io-client';

export function useSocket() {
  const SOCKET_SERVER_URL = '/';
  // Creates a WebSocket connection
  const [socket, setSocket] = useState();

  useEffect(() => {
    if (!socket) {
      setSocket(io(SOCKET_SERVER_URL));
    }
    // Destroys the socket reference
    // when the connection is closed
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);
  return socket;
}
