import React from 'react';
import {UiTextProvider} from './context/UiTextContext';
import {TimeProvider} from './context/TimeContext';
import {UserProvider} from './context/UserContext';
import {SocketProvider} from './context/SocketContext';
import {AlertProvider} from './context/AlertContext';
import LayoutManager from './features/layout/LayoutManager';

const App = function () {
  return (
    <UiTextProvider>
      <SocketProvider>
        <UserProvider>
          <TimeProvider>
            <AlertProvider>
              <LayoutManager />
            </AlertProvider>
          </TimeProvider>
        </UserProvider>
      </SocketProvider>
    </UiTextProvider>
  );
};
export default App;
