import React, {useContext} from 'react';
import ImageGallery from 'react-image-gallery';
import {UserContext} from '../context/UserContext';

export const NonEPullImage = ({hasDefects, isRed, hasSafetyWire, index, id}) => {
  const userContext = useContext(UserContext);
  const simulationStartTime = userContext.simulationStartTime;
  const imageDirectory = getImageDirectory(hasDefects, isRed, hasSafetyWire);
  const imageKey = getImageKey(index, id, simulationStartTime);
  const savedImagePath = getSavedImagePath(imageKey);

  let imagePath;
  if (savedImagePath) {
    imagePath = savedImagePath;
  } else {
    const fileName = getFileName(imageDirectory, simulationStartTime);
    imagePath = `${imageDirectory}/${fileName}`;
    window.localStorage.setItem(imageKey, imagePath);
  }

  let image;
  try {
    image = require(`../assets/${imagePath}.jpg`);
  } catch (err) {
    console.error(err.message);
    return <p>{err.message}</p>;
  }

  // The image gallery expects an array of items, but we only pass one.
  const items = [
    {
      original: image?.default,
      originalAlt: `${isRed ? 'red' : 'black'} model A - ${hasDefects ? 'with' : 'without'} defects ${
        hasSafetyWire ? 'with safety wire' : ''
      }`,
    },
  ];
  return <ImageGallery items={items} showPlayButton={false} showIndex={false} showThumbnails={false} />;
};

/**
 * Get image directory path.
 *
 * @param {boolean} hasDefects
 * @param {boolean} isRed
 * @param {boolean} hasSafetyWire
 * @returns {string} path to directory of images.
 */
const getImageDirectory = (hasDefects, isRed, hasSafetyWire) => {
  return `modelAPics/default/${isRed ? 'red' : 'black'}/${hasDefects ? 'defect' : 'quality'}/${
    hasSafetyWire ? 'safetyWire' : 'noSafetyWire'
  }`;
};

/**
 * Create a unique identifier for the model A image.
 *
 * @param {number} index
 * @param {string} id
 * @param {number} simulationStartTime
 * @returns {string} key in the form `${index}-${id}-${simulationStartTime}`
 */
const getImageKey = (index, id, simulationStartTime) => {
  return `${index}-${id}-${simulationStartTime}`;
};

/**
 * Get saved image path if it exisits.
 *
 * @param {string} imageKey image key in the form `${index}-${id}-${simulationStartTime}`
 *
 * @returns {string|null} the path to the image source, or null if key doesn't match stored path.
 */
const getSavedImagePath = (imageKey) => {
  const storedImagePath = window.localStorage.getItem(imageKey);
  if (storedImagePath) {
    return storedImagePath;
  } else {
    return null;
  }
};

/**
 * Get the variation number which is also the file name.
 *
 * For example, determine which image of a black/defect/without safety wire
 * model A should be shown. Return the number of the image, which when converted to a string
 * is the file name.
 *
 * @param {string} imageDirectory
 * @param {number} simulationStartTime
 *
 * @returns {number} the variation number, which is also the file name.
 */
const getFileName = (imageDirectory, simulationStartTime) => {
  const variationInstanceKey = `${simulationStartTime}-qa-image-storage`;
  const qaImageStorage = JSON.parse(window.localStorage.getItem(variationInstanceKey)) ?? {};

  const variantionInstanceNumber = qaImageStorage?.[imageDirectory] ? qaImageStorage?.[imageDirectory] + 1 : 1;
  const numberOfVariantImages = numberOfImageVariants[imageDirectory];
  const imageNumber = variantionInstanceNumber % numberOfVariantImages;

  let fileName;
  if (imageNumber > 0) {
    fileName = imageNumber;
  } else {
    fileName = numberOfVariantImages;
  }

  // Prevent the red dot variant from showing after the first two times.
  if (
    imageDirectory.includes('red') &&
    variantionInstanceNumber > numberOfVariantImages &&
    (imageNumber === 1 || imageNumber === 2)
  ) {
    fileName = 3;
  }

  qaImageStorage[imageDirectory] = variantionInstanceNumber;
  window.localStorage.setItem(variationInstanceKey, JSON.stringify(qaImageStorage));
  return fileName;
};

/**
 * Set the number of each type of images, so we can
 * reuse images as needed for each type.
 */
const numberOfImageVariants = {
  'modelAPics/default/black/defect/noSafetyWire': 9,
  'modelAPics/default/black/defect/safetyWire': 0,
  'modelAPics/default/black/quality/noSafetyWire': 2,
  'modelAPics/default/black/quality/safetyWire': 0,
  'modelAPics/default/red/defect/noSafetyWire': 6,
  'modelAPics/default/red/defect/safetyWire': 11,
  'modelAPics/default/red/quality/noSafetyWire': 6,
  'modelAPics/default/red/quality/safetyWire': 6,
};
