import React from 'react';
import Box from '@material-ui/core/Box';

const AdminControl = ({onBreak, label, start, pause}) => {
  return (
    <Box display="flex" flexDirection="column">
      {label}{' '}
      {onBreak ? (
        <button onClick={start}>Take worker off break</button>
      ) : (
        <button onClick={pause}>Put worker on break</button>
      )}
    </Box>
  );
};

export default AdminControl;
