import React, {useContext} from 'react';
import {Box, Paper} from '@material-ui/core';
import {pushEntities} from '../../utils/productionFlow/push';
import {newPushEntities} from '../../utils/productionFlow/newPush';
import {pullEntities} from '../../utils/productionFlow/pull';
import ePullEntities from '../../utils/productionFlow/ePull';
import {useContent} from '../../utils/hooks/useContent';
import ProductionRole from './station';
import {pushCompanyId, newPushCompanyId, pullCompanyId, ePullCompanyId} from '../../utils/constants';
import {UserContext} from '../../context/UserContext';
import ProdFloorBinsOrSquare from './binOrSqaure/ProdFloorBinsOrSquare';
import Arrow from './station/Arrow';

export const ProductionFlow = () => {
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  let pullType = false;

  let productionFloorEntities = [];
  const {companyId} = useContext(UserContext);
  switch (companyId) {
    case pushCompanyId:
      productionFloorEntities = pushEntities;
      break;
    case newPushCompanyId:
      productionFloorEntities = newPushEntities;
      break;
    case pullCompanyId:
      productionFloorEntities = pullEntities;
      pullType = true;
      break;
    case ePullCompanyId:
      productionFloorEntities = ePullEntities;
      pullType = true;
      break;
    default:
      break;
  }

  return (
    <Paper>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" p={0.5} pb={2}>
        {productionFloorEntities.map((entity, index) => (
          <Box
            gridColumn={`span ${entity.cols}`}
            gridRow={`span ${entity.rows ?? 1}`}
            style={entity.type === 'bin' ? {zIndex: 100} : {}}
            pl={1.5}
            key={`${entity.label}-${index}`}
          >
            {entity.type === 'bin' && <ProdFloorBinsOrSquare {...entity} pullType={pullType} />}
            {entity.type === 'station' && <ProductionRole channel={entity.label} station {...entity} />}
            {entity.type === 'arrow' && <Arrow />}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};
