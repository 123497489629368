import React, {useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import {CircularProgress} from '@material-ui/core';
import {Button} from '@material-ui/core';
import {Box} from '@material-ui/core';
import {SocketContext} from '../../context/SocketContext';
import {UserContext} from '../../context/UserContext';
import {useApi} from '../../utils/hooks/useApi';
import {useContent} from '../../utils/hooks/useContent';
import {waitingRoomId, simulationOverId} from '../../utils/constants';
import {alphabetize} from '../../utils/helpers';
import {ResetNames} from './ResetNames';

const CompanyReset = function () {
  const [getFieldName] = useContent();

  const userContext = useContext(UserContext);
  const currentCompanyId = userContext?.companyId ?? waitingRoomId;

  const socketContext = useContext(SocketContext);
  const socket = socketContext.socket;

  const handleClick = (e) => {
    socket.emit(`set company`, {companyId: e.currentTarget.id});
    socket.emit('get materials');
  };

  const allowSimReset = currentCompanyId === waitingRoomId || currentCompanyId === simulationOverId;

  const url = `/api/companies`;
  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);

  // Put companies in order.
  const orderedSimulations = alphabetize(result, `${getFieldName('companies', 'order')}`);

  return (
    <>
      {allowSimReset && (
        <>
          <Typography variant="h6">Reset to start of</Typography>
          {loading && <CircularProgress />}
          {loaded &&
            orderedSimulations.map((company) => {
              return (
                <Box my={2} key={company[`${getFieldName('companies', 'uid')}`]}>
                  <Button variant="contained" onClick={handleClick} id={company[`${getFieldName('companies', 'uid')}`]}>
                    {company[`${getFieldName('companies', 'name')}`]} Simulation
                  </Button>
                </Box>
              );
            })}
          <Typography variant="h6">Other actions</Typography>
        </>
      )}
      <Box my={2}>
        <Button variant="outlined" color="secondary" onClick={handleClick} id="simulationOver">
          End Simulation
        </Button>
      </Box>
      <Box my={2}>
        <Button variant="outlined" color="secondary" onClick={handleClick} id="waitingRoom">
          End Simulation and Send All to Waiting Room
        </Button>
      </Box>
      <Box mt={10}>
        <ResetNames socket={socket} />
      </Box>
    </>
  );
};
export default CompanyReset;
