import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {useContent} from '../utils/hooks/useContent';

// Wrapper functional component to pass translated text to class component.
export const Stopwatch = () => {
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  return (
    <StopwatchClass
      stopwatchLabel={getUiText('stopwatch_label')}
      startLabel={getUiText('start')}
      stopLabel={getUiText('stop')}
      resumeLabel={getUiText('resume')}
      restartLabel={getUiText('reset')}
    />
  );
};

// Based with minimal adustments on https://github.com/peterdurham/timers-demo
// @todo: If time, refactor to a functional component for consistancy.
class StopwatchClass extends Component {
  state = {
    timerOn: false,
    timerStart: 0,
    timerTime: 0,
  };

  startTimer = () => {
    this.setState({
      timerOn: true,
      timerTime: this.state.timerTime,
      timerStart: Date.now() - this.state.timerTime,
    });
    this.timer = setInterval(() => {
      this.setState({
        timerTime: Date.now() - this.state.timerStart,
      });
    }, 10);
  };

  stopTimer = () => {
    this.setState({timerOn: false});
    clearInterval(this.timer);
  };

  resetTimer = () => {
    this.setState({
      timerStart: 0,
      timerTime: 0,
    });
  };

  render() {
    const {timerTime} = this.state;
    let centiseconds = ('0' + (Math.floor(timerTime / 10) % 100)).slice(-2);
    let seconds = ('0' + (Math.floor(timerTime / 1000) % 3600)).slice(-2);
    return (
      <Box display="flex" mt={1} mb={4} justifyContent="center" alignItems="center" flexDirection="column">
        <Paper>
          <Box p={1} width={200} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography variant="caption">{this.props.stopwatchLabel}</Typography>
            <Typography variant="h6">
              {seconds} : {centiseconds}
            </Typography>
            <Box mt={1}>
              {this.state.timerOn === false && this.state.timerTime === 0 && (
                <Button variant="contained" size="small" color="primary" onClick={this.startTimer}>
                  {this.props.startLabel}
                </Button>
              )}
              {this.state.timerOn === true && (
                <Button variant="outlined" size="small" color="inherit" onClick={this.stopTimer}>
                  {this.props.stopLabel}
                </Button>
              )}
              <Box>
                {this.state.timerOn === false && this.state.timerTime > 0 && (
                  <Button variant="contained" size="small" color="primary" onClick={this.startTimer}>
                    {this.props.resumeLabel}
                  </Button>
                )}
                {this.state.timerOn === false && this.state.timerTime > 0 && (
                  <Button size="small" color="inherit" onClick={this.resetTimer}>
                    {this.props.restartLabel}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  }
}
