import React from 'react';
import {Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {RoleHighlighter} from '../../components/Highlighter';
import {useTimeBasedAlert} from '../../utils/hooks/useTimedAlert';
import {InfoCard} from '../../components/InfoCard';
import {useContent} from '../../utils/hooks/useContent';
import {useChecklist} from '../../utils/hooks/useChecklist';

export const Action = function ({action, additionalInfo, minute, assetIds = null}) {
  // Split actions string into individual actions.
  const actionArray = action.split('-').filter(Boolean);
  const [checked, handleChecklist] = useChecklist();
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  // If there are alerts associated with this action, send them to the Alerts section.
  useTimeBasedAlert(assetIds, minute * 60);

  return (
    <>
      <Typography component="h3" variant="overline" gutterBottom>
        {getUiText('tasks')}
      </Typography>
      <Box component="div" mb={6}>
        {actionArray.map((action, index) => {
          const checkId = `action-${minute}-index-${index}`;
          return (
            <Box component="div" mb={2} key={checkId}>
              <FormControlLabel
                key={action}
                control={
                  <Checkbox
                    name={action}
                    id={checkId}
                    color="primary"
                    checked={checked.includes(checkId)}
                    onChange={handleChecklist}
                  />
                }
                label={
                  <Typography color="textSecondary">
                    <RoleHighlighter text={action} />
                  </Typography>
                }
              />
            </Box>
          );
        })}
      </Box>
      {additionalInfo && <InfoCard label={getUiText('additional_info')} bodyText={additionalInfo} />}
    </>
  );
};
