import React, {useContext} from 'react';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dashboard from './Dashboard';
import Transitional from './Transitional';
import {UserContext} from '../../context/UserContext';
import {productionRoles} from '../../utils/constants';

const theme = createMuiTheme({
  typography: {
    overline: {
      fontWeight: 500,
    },
  },
  palette: {
    active: {
      main: '#00D115',
      light: '#D0EECF',
    },
    blocked: {
      main: '#D1B000',
      light: '#EEE5CF',
    },
    'on break': {
      main: '#D10000',
      light: '#EED1CF',
    },
  },
});

const LayoutManager = function () {
  const userContext = useContext(UserContext);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {Object.keys(productionRoles).includes(userContext.companyId) && userContext.realName ? (
        <Dashboard />
      ) : (
        <Transitional
          room={userContext.companyId}
          isAdmin={userContext.isAdmin}
          realName={userContext.realName}
          roleId={userContext.roleId}
        />
      )}
    </ThemeProvider>
  );
};
export default LayoutManager;
