import React from 'react';
import WelcomeFlow from '../onboarding/WelcomeFlow';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Banner} from '../../components/Banner';
import AdminMenu from '../navigation/AdminMenu';
import Roles from '../../components/Roles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

export default function Transitional({room, isAdmin, realName, roleId}) {
  const classes = useStyles();

  return (
    <>
      <AppBar position="absolute" color="primary" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography variant="h6" color="inherit" noWrap>
            Virtual Bootcamp
          </Typography>
          {isAdmin && <AdminMenu />}
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {!roleId && (
            <>
              <Typography component="h1" variant="h2">
                Welcome!
              </Typography>
              <Typography variant="body1" gutterBottom>
                Please select your role to begin.
              </Typography>
              <Roles links disableLinksWithNames />
            </>
          )}
          {roleId && (
            <>
              {room === 'simulationOver' ? (
                <Banner text="Simulation Over" />
              ) : (
                (room === 'waitingRoom' || !realName) && <WelcomeFlow />
              )}
            </>
          )}
        </Paper>
      </main>
    </>
  );
}
