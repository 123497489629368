import React, {useContext, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AlertContext} from '../../context/AlertContext';
import {Alert} from './Alert';
import {useOnScreen} from '../../utils/hooks/useOnScreen';
import {useContent} from '../../utils/hooks/useContent';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  newAlert: {
    marginBottom: theme.spacing(2),
  },
  alertNotice: {
    position: 'fixed',
    top: 130,
    right: 36,
    zIndex: 20000,
    borderRadius: 25,
    border: '1px solid',
    borderColor: theme.palette.primary,
  },
}));

export default function NewAlert() {
  const classes = useStyles();
  const {
    alerts,
    hasNewAlert,
    setHasNewAlert,
    setGoToNewAlert,
    showNewAlertWarning,
    setShowNewAlertWarning,
  } = useContext(AlertContext);

  const ref = useRef();
  const onScreen = useOnScreen(ref);

  const handleCloseNewAlert = () => {
    setHasNewAlert(false);
    setGoToNewAlert(false);
  };

  useEffect(() => {
    setShowNewAlertWarning(!onScreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen]);

  return (
    <>
      <div className={classes.root} ref={ref}>
        {hasNewAlert && (
          <div className={classes.newAlert} id="new-alert">
            <Alert key={alerts?.[0]?.id} alert={alerts?.[0]} handleClose={handleCloseNewAlert} newAlert />
          </div>
        )}
      </div>
      {hasNewAlert && showNewAlertWarning && (
        <AlertNotVisible
          alertRef={ref}
          setGoToNewAlert={setGoToNewAlert}
          setShowNewAlertWarning={setShowNewAlertWarning}
        />
      )}
    </>
  );
}

const AlertNotVisible = ({alertRef, setGoToNewAlert, setShowNewAlertWarning}) => {
  const classes = useStyles();

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const handleClick = () => {
    setGoToNewAlert(true);
    setShowNewAlertWarning(false);
    setTimeout(() => {
      alertRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
    }, 200);
  };

  return (
    <MuiAlert
      severity="info"
      icon={false}
      action={
        <Button color="inherit" size="small" onClick={handleClick}>
          {getUiText('view_alert')}
        </Button>
      }
      classes={{root: classes.alertNotice}}
    >
      {getUiText('new_alert')}
    </MuiAlert>
  );
};
