import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import OsTabs from './OsTabs';
import {TimeContext} from '../../context/TimeContext';
import {useOs} from '../../utils/hooks/useOs';
import AppBar from '../navigation/AppBar';
import {useContent} from '../../utils/hooks/useContent';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100%',
    height: '100%',
  },
  content: {
    display: 'flex',
    width: '100%',
  },
  drawer: {
    zIndex: 1399,
  },
}));

export default function OS() {
  const [getFieldName] = useContent();

  const classes = useStyles();
  const timeContext = useContext(TimeContext);
  const currentMinute = timeContext.currentMinute;
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [selectedResponse, setSelectedResponse] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const [assets, responses, actions] = useOs();

  useEffect(() => {
    if (actions) {
      const promptsAtMinute = actions.findIndex((prompt) => {
        return prompt[`${getFieldName('prompts', 'minute')}`] - currentMinute === 0;
      });
      if (promptsAtMinute > -1) {
        setSelectedMinute(promptsAtMinute);
        setTabIndex(2);
      }
    }
    // Do not rerun effect on getFieldName change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMinute, actions]);

  return (
    <>
      <AppBar variant="OS" />
      <div className={classes.root}>
        <div className={classes.content}>
          {actions && (
            <OsTabs
              assets={assets}
              responses={responses}
              actions={actions}
              selectedMinute={selectedMinute}
              setSelectedMinute={setSelectedMinute}
              selectedResponse={selectedResponse}
              setSelectedResponse={setSelectedResponse}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
            />
          )}
        </div>
      </div>
    </>
  );
}
