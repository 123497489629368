import {ControlledInput} from './Input';
import {Typography, Divider} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import {MultiplierInputs} from './MultiplierInputs';
import {ReadOnlyNotice} from './ReadOnlyNotice';
import {useContent} from '../../utils/hooks/useContent';
import {Instructions} from './Instructions';

const materialsLines = ['material1', 'material2', 'material3', 'material4', 'material5', 'material6'];

export const Profit = ({fields, handleFieldChange, disabled}) => {
  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  return (
    <>
      {disabled && <ReadOnlyNotice />}
      <Instructions text={getUiText('worksheet_instructions_profit')} />
      <Divider />
      <Box py={2}>
        <Typography variant="body1" gutterBottom>
          {getUiText('revenue')}
        </Typography>
        <MultiplierInputs
          fields={fields}
          handleFieldChange={handleFieldChange}
          fieldId1="numberSold"
          fieldId2="sellingPrice"
          field1label={getUiText('parts_sold')}
          field2label={getUiText('selling_price')}
          totalLabel={getUiText('total_revenue')}
          disabled={disabled}
        />
        <Box pt={2}>
          <MultiplierInputs
            fields={fields}
            handleFieldChange={handleFieldChange}
            fieldId1="numberSold2"
            fieldId2="sellingPrice2"
            field1label={getUiText('parts_sold')}
            field2label={getUiText('selling_price')}
            totalLabel={getUiText('total_revenue')}
            disabled={disabled}
          />
        </Box>
      </Box>
      <Divider />
      <Box py={2}>
        <Typography variant="body1" gutterBottom>
          {getUiText('total_cost_of_goods_sold')}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {getUiText('total_raw_material_costs')}
        </Typography>
        <Box pb={2}>
          {materialsLines.map((line) => (
            <Box display="flex" alignItems="center" justifyContent="flex-start" py={1}>
              <Box mr={3}>
                <ControlledInput
                  id={`${line}name`}
                  type="text"
                  value={`${fields[`${line}name`]}` ? `${fields[`${line}name`]}` : ''}
                  handleFieldChange={handleFieldChange}
                  label="Material"
                  disabled={disabled}
                />
              </Box>
              <MultiplierInputs
                fields={fields}
                handleFieldChange={handleFieldChange}
                fieldId1={`${line}quantity`}
                fieldId2={`${line}unitCost`}
                field1label="Quantity"
                field2label="Unit Cost"
                disabled={disabled}
              />
            </Box>
          ))}
        </Box>

        <Typography variant="body2" gutterBottom>
          {getUiText('direct_labor_costs')}
        </Typography>
        <Box pt={1} pb={2}>
          <MultiplierInputs
            fields={fields}
            handleFieldChange={handleFieldChange}
            fieldId1="numLaborEmployees"
            fieldId2="minutesWorked"
            field1label={getUiText('employees')}
            field2label={getUiText('number_of_minutes_worked')}
            totalLabel={getUiText('total')}
            multiplier="@$10/hr  x"
            disabled={disabled}
          />
        </Box>
        <Typography variant="body2">{getUiText('overhead_labor_costs')}</Typography>
        <Box pt={1} pb={2}>
          <MultiplierInputs
            fields={fields}
            handleFieldChange={handleFieldChange}
            fieldId1="numOverheadEmployees"
            fieldId2="minutesWorked"
            field1label={getUiText('employees')}
            field2label={getUiText('number_of_minutes_worked')}
            totalLabel={getUiText('total')}
            multiplier="@$40/hr  x"
            disabled={disabled}
          />
        </Box>
        <Typography variant="body2" gutterBottom>
          {getUiText('total_cost_of_goods_sold')}
        </Typography>
        <Box display="flex" alignItems="center" pt={1} pb={2}>
          <ControlledInput
            id={`totalRaw`}
            type="text"
            value={`${fields['totalRaw']}`}
            handleFieldChange={handleFieldChange}
            label={getUiText('raw_material')}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            disabled={disabled}
          />
          <Box px={1}>+</Box>
          <ControlledInput
            id={`totalDirect`}
            type="text"
            value={`${fields[`totalDirect`]}`}
            handleFieldChange={handleFieldChange}
            label={getUiText('direct_labor')}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            disabled={disabled}
          />
          <Box px={1}>+</Box>
          <ControlledInput
            id={`totalOverhead`}
            type="text"
            value={`${fields['totalOverhead']}`}
            handleFieldChange={handleFieldChange}
            label={getUiText('overhead_labor')}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            disabled={disabled}
          />
          <Box px={1}>=</Box>
          <ControlledInput
            id={`totalCost`}
            type="text"
            value={`${fields['totalCost']}`}
            handleFieldChange={handleFieldChange}
            label={getUiText('total_cost')}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            disabled={disabled}
          />
        </Box>
      </Box>
      <Divider />
      <Box pt={1} pb={2}>
        <Typography variant="body1" gutterBottom>
          {getUiText('operating_profit')}
        </Typography>
        <Box pt={1} pb={2} display="flex" alignItems="center">
          <ControlledInput
            id={`totalRevenue`}
            type="number"
            value={`${fields[`totalRevenue`]}`}
            handleFieldChange={handleFieldChange}
            label={getUiText('revenue')}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            disabled={disabled}
          />
          <Box px={1}>-</Box>
          <ControlledInput
            id={`totalCost`}
            type="number"
            value={`${fields['totalCost']}`}
            handleFieldChange={handleFieldChange}
            label={getUiText('costs')}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            disabled={disabled}
          />
          <Box px={1}>=</Box>
          <ControlledInput
            id={`opProfit`}
            type="number"
            value={`${fields['opProfit']}`}
            handleFieldChange={handleFieldChange}
            label={getUiText('operating_profit')}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            disabled={disabled}
          />
        </Box>
      </Box>
    </>
  );
};
