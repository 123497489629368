import React, {useContext, useEffect, useState} from 'react';
import {Grid, Box, Divider, makeStyles} from '@material-ui/core';
import {UserContext} from '../../context/UserContext';
import {SocketContext} from '../../context/SocketContext';
import {useSocketChannel} from '../../utils/hooks/useSocketChannel';
import {useContent} from '../../utils/hooks/useContent';
import ProductionRole from './station';
import {productionRoles, miscAdminButtons} from '../../utils/constants';
import {getSimulationType} from '../../utils/helpers';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {MaterialsSelect} from './MaterialsSelect';

const modal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '700px',
  bgcolor: 'white',
  border: '1px solid #000',
  boxShadow: 24,
  padding: '10px',
};

const useStyles = makeStyles({
  modal: modal,
});

export const AdminProductionFloorUtils = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const userContext = useContext(UserContext);

  const socketContext = useContext(SocketContext);
  //eslint-disable-next-line no-unused-vars
  const [cycleData, cycleStart, cycleStop] = useSocketChannel('cycleTime', socketContext.socket);
  //eslint-disable-next-line no-unused-vars
  const [redWireChangeData, initializeRedWireChange] = useSocketChannel('redWireChangeOrder', socketContext.socket);
  const redWireClicked = redWireChangeData?.isRedWireChangeOrderInitiated;

  const [displaySafetyWiresData, initiateDisplaySafetyWires] = useSocketChannel(
    'displaySafetyWires',
    socketContext.socket
  );
  const displaySafetyWires = displaySafetyWiresData?.displaySafetyWires;

  const [communicationSquareData] = useSocketChannel('stakedBoardCommunicationSquare', socketContext.socket);

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();
  const companyId = userContext.companyId;
  const socketChannels = companyId ? productionRoles[companyId].productionRoles : null;
  const pullTypeSim = getSimulationType(companyId) === 'pullType';

  useEffect(() => {
    socketContext.socket.emit('get bot state');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get initial time. This is necessary if the timer is paused,
  // since it won't emit when paused.
  useEffect(() => {
    socketContext.socket.emit('get cycle time');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Determine whether the red wire change order has been initiated.
  useEffect(() => {
    socketContext.socket.emit('get redWireChangeOrder');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Determine if the stakedBoardCommunicationSquare has been flipped.
  // This is only relevant to Pull and ePull.
  useEffect(() => {
    socketContext.socket.emit('get stakedBoardCommunicationSquare');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flipStakingPressComm = () => {
    socketContext.socket.emit(`flip stakedBoardCommunicationSquare`);
  };

  const renderMiscAdminButtons = ({label, preAction, postAction}) => {
    const ButtonWrapper = ({children}) => {
      return (
        <Grid item xs={12} lg={4}>
          <Box display="flex" flexDirection="column">
            {children}
          </Box>
        </Grid>
      );
    };

    switch (label) {
      case 'cycle_time':
        return (
          <ButtonWrapper key={label}>
            <button id={label} onClick={cycleStart} disabled={cycleData.active}>
              {cycleData.active ? getUiText(postAction) : getUiText(preAction)}
            </button>
          </ButtonWrapper>
        );
      case 'stop_cycle_time':
        return (
          <ButtonWrapper key={label}>
            <button id={label} onClick={cycleStop} disabled={!cycleData.active}>
              {!cycleData.active ? getUiText(postAction) : getUiText(preAction)}
            </button>
          </ButtonWrapper>
        );
      case 'red_wire_change':
        return (
          <ButtonWrapper key={label}>
            <button id={label} onClick={initializeRedWireChange} disabled={redWireClicked}>
              {redWireClicked ? getUiText(postAction) : getUiText(preAction)}
            </button>
          </ButtonWrapper>
        );
      case 'safety_wire_change':
        return (
          <ButtonWrapper key={label}>
            <button id={label} onClick={initiateDisplaySafetyWires} disabled={displaySafetyWires || !redWireClicked}>
              {displaySafetyWires ? getUiText(postAction) : getUiText(preAction)}
            </button>
          </ButtonWrapper>
        );
      case 'flip_staking_press_communication':
        if (pullTypeSim) {
          return (
            <ButtonWrapper key={label}>
              <button id={label} onClick={flipStakingPressComm} disabled={communicationSquareData.isUp}>
                {communicationSquareData.isUp ? getUiText(postAction) : getUiText(preAction)}
              </button>
            </ButtonWrapper>
          );
        } else {
          break;
        }
      default:
        return null;
    }
  };

  if (!userContext.isAdmin) return null;

  return (
    <div>
      <IconButton aria-label="settings" onClick={handleOpen}>
        <SettingsIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper className={classes.modal}>
          <Typography gutterBottom variant="h6">
            Production Floor Admin Tools
          </Typography>
          <Divider />
          {userContext.isAdmin && (
            <Grid container spacing={4} style={{marginBottom: '16px', marginTop: '16px'}}>
              {miscAdminButtons.map((button) => renderMiscAdminButtons(button))}
              <Grid item xs={12} lg={4}>
                <Box display="flex" flexDirection="column">
                  <MaterialsSelect />
                </Box>
              </Grid>
            </Grid>
          )}
          <Divider />
          <Grid container spacing={4} style={{marginTop: '16px'}}>
            {socketChannels &&
              socketChannels.map((channel) => (
                <Grid item key={channel} xs={12} lg={4}>
                  <ProductionRole channel={channel} userIsAdmin={userContext.isAdmin} adminControl />
                </Grid>
              ))}
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
};
