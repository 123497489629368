import {pushCompanyId, newPushCompanyId, pullCompanyId, ePullCompanyId} from './constants';

export const getTwoDigits = (number) => {
  switch (number?.toString().length) {
    case 0:
      return '00';
    case 1:
      return `0${number}`;
    default:
      return number;
  }
};

export const getFormatedMinutesAndSeconds = (totalSeconds) => {
  if (!totalSeconds) {
    return '';
  }
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.round(totalSeconds % 60);
  return `${minutes}:${getTwoDigits(seconds)}`;
};

export const getMaterialQuantity = (materialBaseName, materials) => {
  let quantity = 0;
  for (const [key, value] of Object.entries(materials)) {
    // @todo: Consider refactoring to `.startsWith`.
    if (key.includes(materialBaseName)) {
      quantity += value;
    }
  }
  return quantity;
};

// Given a materials base name, ie 'wires', return an object of
// material quantity keyed by the color (red, black, or yellow).
export const getMaterialColors = (materialBaseName, materials) => {
  let colorsMap = {};
  for (const [key, value] of Object.entries(materials)) {
    if (key.includes(materialBaseName) && value > 0) {
      if (key.includes('Red')) {
        colorsMap['red'] = value;
      } else if (key.includes('Yellow')) {
        colorsMap['yellow'] = value;
      } else if (key.includes('Blue')) {
        colorsMap['blue'] = value;
      } else {
        colorsMap['black'] = value;
      }
    }
  }
  return colorsMap;
};

// If there are colors other than black, get a string
// of colors and values, ie "30 red / 2 black"
export const getMaterialColorsString = (colorsMap) => {
  let string = '';
  let numColors = Object.keys(colorsMap).length;
  for (const [key, value] of Object.entries(colorsMap)) {
    // If the only color is black, return an empty string.
    if (key === 'black' && numColors === 1) {
      return string;
    }
    string = string.length > 0 ? string + ' / ' : string;
    string += `${value} ${key}`;
  }
  return string;
};

export const getSimulationType = (companyId) => {
  switch (companyId) {
    case pushCompanyId:
    case newPushCompanyId:
      return 'pushType';
    case pullCompanyId:
    case ePullCompanyId:
      return 'pullType';
    default:
      return;
  }
};

export const alphabetize = (array, fieldToSortOn) => {
  if (!array) {
    return [];
  }
  array?.sort(function (a, b) {
    const textA = a[fieldToSortOn]?.toString()?.toUpperCase();
    const textB = b[fieldToSortOn]?.toString()?.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  return array;
};
