import React from 'react';
import {CalculatingTable} from './CalculatingTable';
import {ReadOnlyNotice} from './ReadOnlyNotice';
import {useContent} from '../../utils/hooks/useContent';
import {Instructions} from './Instructions';

const rows = ['05', '10', '40'];

export const Inventory = ({fields, handleFieldChange, disabled}) => {
  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();
  const columns = [getUiText('wires'), getUiText('terminals'), getUiText('boards')];
  return (
    <>
      {disabled && <ReadOnlyNotice />}
      <Instructions text={getUiText('worksheet_instructions_inventory')} />
      <CalculatingTable
        rows={rows}
        columns={columns}
        fields={fields}
        handleFieldChange={handleFieldChange}
        disabled={disabled}
        label={getUiText('time')}
        rowPrefix={`${getUiText('minute_short')} :`}
      />
    </>
  );
};
