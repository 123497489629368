import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';
import {getTwoDigits} from '../../utils/helpers';
import {TimeContext} from '../../context/TimeContext';
import {useContent} from '../../utils/hooks/useContent';

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    overflowY: 'auto',
    overflowX: 'visible',
    height: '100%',
    width: 'auto',
    maxHeight: 'calc(100vh - 116px)',
    paddingTop: 20,
  },
}));

export default function MinuteDrawer({selectedIndex, setSelectedIndex, data, drawerType}) {
  const [getFieldName] = useContent();

  const classes = useStyles();
  const timeContext = useContext(TimeContext);
  const currentMinute = timeContext.currentMinute;

  // Move the selected minute back to the last non-disabled minute.
  useEffect(() => {
    if (
      drawerType === 'minute' &&
      data?.[selectedIndex]?.[`${getFieldName('prompts', 'minute')}`] - currentMinute > 0
    ) {
      setSelectedIndex(selectedIndex - 1);
    }
  }, [drawerType, selectedIndex, currentMinute, data, setSelectedIndex, getFieldName]);

  return (
    <List className={classes.drawerContainer}>
      {data &&
        data.map((data, index) => {
          const minute = getTwoDigits(data[`${getFieldName('prompts', 'minute')}`]);
          const id = data[`${getFieldName('prompts', 'uid')}`];
          const variant = selectedIndex === index ? 'default' : 'outlined';
          const disabled =
            data[`${getFieldName('prompts', 'minute')}`] - currentMinute > 0 || drawerType === 'disabled';

          const handleClick = () => {
            setSelectedIndex(index);
          };

          return (
            <ListItem key={id} id={id}>
              <Chip
                button="true"
                label={drawerType === 'response' ? index + 1 : `:${minute}`}
                variant={variant}
                disabled={drawerType === 'response' ? false : disabled}
                color="primary"
                onClick={handleClick}
                size="small"
              />
            </ListItem>
          );
        })}
    </List>
  );
}
