import React, {useState, useContext, useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Video} from '../../../components/Video';
import {useContent} from '../../../utils/hooks/useContent';
import {AlertContext} from '../../../context/AlertContext';

const InfoModal = ({title, videoUrl}) => {
  const [open, setOpen] = useState(false);
  const {goToNewAlert, setGoToNewAlert, setShowNewAlertWarning} = useContext(AlertContext);

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const handleClickOpen = () => {
    setOpen(true);
    // Set a flag so if a new alert comes in,
    // an overlay will display.
    setShowNewAlertWarning(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Reset goToNewAlert.
    setGoToNewAlert(false);
  };

  // Hide modal to go to a new alert.
  useEffect(() => {
    if (goToNewAlert) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToNewAlert]);

  return (
    <>
      <Box mt={-0.2}>
        <IconButton aria-label={`Open a video of a ${title}`} size={'small'} onClick={handleClickOpen}>
          <InfoIcon fontSize="small" />
        </IconButton>
      </Box>
      <Dialog onClose={handleClose} aria-labelledby={`${title}-dialog`} open={open} fullWidth maxWidth="md">
        <DialogTitle id={`${title}-dialog`} onClose={handleClose}>
          {getUiText(title)} – {getUiText('what_do_they_do')}
        </DialogTitle>
        <MuiDialogContent dividers>
          <Box mb={3} maxWidth={600}>
            {/* <Typography component="h3" variant="overline" gutterBottom>
              {getUiText('role')}
            </Typography>
            <Typography component="p" gutterBottom>
              {getUiText(`${title}_role`)}
            </Typography>
            <Typography component="h3" variant="overline" gutterBottom>
              {getUiText('goal')}
            </Typography>
            <Typography component="p" gutterBottom>
              {getUiText(`${title}_goal`)}
            </Typography> */}
          </Box>
          {videoUrl ? <Video url={videoUrl} /> : <>Video coming soon.</>}
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

export default InfoModal;

const DialogTitle = (props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle {...other}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
    </MuiDialogTitle>
  );
};
