import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import OS from '../os_book/OS';
import Stage from './Stage';
import ColumnResizer from 'react-column-resizer';

const minColumnWidth = 200;
const resizerWidth = 8;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  paper: {
    height: '100vh',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  columnResizer: {
    backgroundColor: 'white',
    width: resizerWidth,
    minWidth: resizerWidth,
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  td: {
    padding: 0,
    height: '100vh',
    overflow: 'hidden',
    width: '50vw',
  },
  srOnly: {
    position: 'absolute',
    left: -10000,
    top: 'auto',
    width: 1,
    height: 1,
    overflow: 'hidden',
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <table className={classes.table}>
        <thead className={classes.srOnly}>
          <tr>
            <th>Operating System Book</th>
            <th>Production Floor</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.td}>
              <Paper square className={classes.paper}>
                <OS />
              </Paper>
            </td>
            <ColumnResizer className={classes.columnResizer} minWidth={minColumnWidth} />
            <td className={classes.td}>
              <Paper square className={classes.paper}>
                <Stage />
              </Paper>
            </td>
          </tr>
        </tbody>
      </table>
    </main>
  );
}
