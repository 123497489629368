import React, {useEffect} from 'react';
import Pdf from 'react-to-pdf';
import {makeStyles} from '@material-ui/core/styles';
import {Paper, Box, Typography, Button} from '@material-ui/core';
import axios from 'axios';
import {useFormFields} from '../../utils/hooks/useFormFields';
import {Inventory} from './Inventory';
import {ProcessTime} from './ProcessTime';
import {Profit} from './Profit';
import {Inspection} from './Inspection';
import {Order} from './Order';
import {Invoice} from './Invoice';
import {ApproveReject} from '../worksheets/ApproveReject';
import {formIds} from '../../utils/constants';

// Create a ref for downloading a PDF.
const ref = React.createRef();
// @todo: Does this need to be translated?
const pdfButtonLabel = 'Generate PDF';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: '100%',
    minWidth: 500,
  },
}));

export const Form = ({
  initialFieldState,
  formId,
  companyId,
  simulationStartTime,
  disabled,
  title = null,
  batchInfo = null,
  newAlert = false,
  batchReviewComplete = false,
  setBatchReviewComplete = null,
}) => {
  const classes = useStyles();

  const [fields, handleFieldChange] = useFormFields({
    ...initialFieldState,
  });

  // Update the form state in the database when any value changes.
  useEffect(() => {
    axios
      .post('/api/forms', {
        ...fields,
        companyId: companyId,
        simulationStartTime: simulationStartTime,
        formId: formId,
      })
      .then(function () {
        console.log('Successfully updated the form.');
      })
      .catch(function (err) {
        console.log(`Error: ${err}`);
      });
  }, [fields, formId, companyId, simulationStartTime]);

  // Get the form asset id, which is the same across all 4 companies.
  const formAssetId = formId.split('-')[0];

  const isApproveRejectForm = formAssetId === 'modelAQABatchAlert' || formAssetId === 'modelACustomerBatchAlert';

  if (isApproveRejectForm) {
    return (
      <ApproveReject
        handleFieldChange={handleFieldChange}
        fields={fields}
        alert={batchInfo}
        newAlert={newAlert}
        batchReviewComplete={batchReviewComplete}
        setBatchReviewComplete={setBatchReviewComplete}
      />
    );
  } else {
    return (
      <Box display="flex" flexDirection="column" alignItems="start">
        <Paper className={classes.paper} ref={ref}>
          <Box p={2} width={1}>
            {title && (
              <Typography component="h3" variant="h6" gutterBottom>
                {title}
              </Typography>
            )}
            {formAssetId === formIds.inventory && (
              <Inventory handleFieldChange={handleFieldChange} fields={fields} disabled={disabled} />
            )}
            {formAssetId === formIds.processTime && (
              <ProcessTime handleFieldChange={handleFieldChange} fields={fields} disabled={disabled} />
            )}
            {formAssetId === formIds.profit && (
              <Profit handleFieldChange={handleFieldChange} fields={fields} disabled={disabled} />
            )}
            {formAssetId === formIds.inspection && <Inspection disabled />}
            {formAssetId === formIds.order && (
              <Order handleFieldChange={handleFieldChange} fields={fields} disabled={disabled} />
            )}
            {formAssetId === formIds.invoice && (
              <Invoice handleFieldChange={handleFieldChange} fields={fields} disabled={disabled} />
            )}
          </Box>
        </Paper>
        <Box mt={5}>
          <Pdf targetRef={ref} filename={`${title}-${Date.now()}`}>
            {({toPdf}) => (
              <Button variant="contained" onClick={toPdf}>
                {pdfButtonLabel}
              </Button>
            )}
          </Pdf>
        </Box>
      </Box>
    );
  }
};
