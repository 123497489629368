import React, {useContext, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useApi} from '../../utils/hooks/useApi';
import {SocketContext} from '../../context/SocketContext';
import {useContent} from '../../utils/hooks/useContent';
import {permissionsKey} from '../../utils/constants';
import {alphabetize} from '../../utils/helpers';

export const AlertTrigger = () => {
  const [getFieldName] = useContent();

  const url = `/api/assets?${getFieldName('assets', 'presentation')}=Facilitator Triggered`;
  const socketContext = useContext(SocketContext);
  const socket = socketContext.socket;

  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);

  // Sort alphebetically by admin name.
  const orderedAlerts = alphabetize(result, `${getFieldName('assets', 'adminName')}`);

  return (
    <>
      <Typography variant="h6">Trigger an Alert</Typography>
      <Typography variant="body2">This only works during a simulation.</Typography>
      <List>
        {orderedAlerts?.map((asset) => {
          return (
            <AlertListItem
              key={asset[`${getFieldName('assets', 'uid')}`]}
              id={asset[`${getFieldName('assets', 'uid')}`]}
              title={asset[`${getFieldName('assets', 'adminName')}`]}
              socket={socket}
            />
          );
        })}
      </List>
    </>
  );
};

const AlertListItem = ({id, title, socket}) => {
  const [open, setOpen] = useState(false);
  const [permission, setPermission] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setPermission(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    // Append a timestamp to the `id` in order to allow the same alert to be displayed more than once.
    socket.emit('trigger alert', {id: `${id}-${Date.now()}`, assetId: id, permissionRequired: permission});
    handleClose();
  };

  return (
    <>
      <ListItem button component="a" key={id} id={id} onClick={handleClickOpen}>
        {title}
      </ListItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Select user permission required to view the alert:
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel id="permission-required">Permission Required</InputLabel>
            <Select labelId="permission-select-label" id="permission-select" value={permission} onChange={handleChange}>
              {Object.keys(permissionsKey).map((permission) => (
                <MenuItem value={permissionsKey[permission]}>{permissionsKey[permission]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            cancel
          </Button>
          <Button onClick={handleSend} variant="contained" color="primary" autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
