import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import {Asset} from '../../components/Asset';
import {useContent} from '../../utils/hooks/useContent';
import {Time} from '../../features/timer/Time';
import Typography from '@material-ui/core/Typography';
import {FormLoader} from '../worksheets/FormLoader';
import {InvoiceResponse} from '../worksheets/InvoiceReponse';

const batchQaType = 'modelAQABatchAlert';
const batchCustomerType = 'modelACustomerBatchAlert';
const ePullOrderPlaced = 'ePullOrderPlaced';
const invoiceSent = 'invoiceSent';

const useStyles = makeStyles((theme) => ({
  alert: {
    position: 'relative',
  },
  alertTitle: {
    width: '100%',
  },
}));

export const Alert = ({alert, handleClose = null, newAlert = false}) => {
  const classes = useStyles();

  const [fullView, setFullView] = useState(false);

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const [batchReviewComplete, setBatchReviewComplete] = useState(false);

  if (!alert) {
    return <></>;
  }

  const showDismissIcon = () => {
    if (!handleClose) {
      return false;
    }
    if (alert?.type === batchQaType || alert?.type === batchCustomerType) {
      if (!batchReviewComplete) {
        return false;
      }
    }
    return true;
  };

  const showActionButton = () => {
    return alert?.type !== ePullOrderPlaced;
  };

  return (
    <>
      <MuiAlert
        severity="info"
        className={classes.alert}
        icon={newAlert ? <ReportProblemOutlinedIcon fontSize="inherit" /> : <></>}
        action={
          <Box display="flex" flexDirection="row" alignContent="center">
            {showActionButton() && (
              <Button color="inherit" size="small" onClick={() => setFullView(!fullView)}>
                {fullView ? <>{getUiText('hide')}</> : <>{getUiText('show_more')}</>}
              </Button>
            )}
            {showDismissIcon() && (
              <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          </Box>
        }
      >
        <AlertTitle>{getAlertTitle(alert, getUiText)}</AlertTitle>
      </MuiAlert>
      {fullView && getAlertFullView(alert, newAlert, batchReviewComplete, setBatchReviewComplete)}
    </>
  );
};

const getAlertTitle = (alert, getUiText) => {
  switch (alert?.type) {
    case batchQaType:
    case batchCustomerType:
      return (
        <Box display="flex" alignItems="center">
          <Time totalSeconds={alert.seconds} variant="h6" />
          <Box component="span" p={1}>
            {' '}
          </Box>
          <Box component="span" display="inline-flex" alignItems="center">
            <Typography variant="subtitle1">
              {alert?.type === batchQaType ? getUiText('ready_for_qa_review') : getUiText('ready_for_customer_review')}
            </Typography>
          </Box>
        </Box>
      );
    case ePullOrderPlaced:
      return (
        <Box display="flex" alignItems="center">
          <Time totalSeconds={alert?.seconds} variant="h6" />
          <Box component="span" p={1}>
            {' '}
          </Box>
          <Box component="span" display="inline-flex" alignItems="center">
            <Typography variant="subtitle1">
              {getUiText('order_number')} {alert?.orderNumber + 1}: {getUiText('placed_by_customer_for')}{' '}
              {getUiText('model_a')} {getUiText('of_type')} {alert?.modelAVariant}
            </Typography>
          </Box>
        </Box>
      );
    case invoiceSent:
      return (
        <Box display="flex" alignItems="center">
          <Time totalSeconds={alert?.seconds} variant="h6" />
          <Box component="span" p={1}>
            {' '}
          </Box>
          <Box component="span" display="inline-flex" alignItems="center">
            <Typography variant="subtitle1">
              {getUiText('invoice_sent_for')} {alert.invoiceData.material}: {alert.invoiceData.quantity} @ {'$'}
              {alert.invoiceData.cost} per {alert.invoiceData.material}. {getUiText('total_due')} {'$'}
              {alert.invoiceData.total}
            </Typography>
          </Box>
        </Box>
      );
    default:
      return (
        <Asset
          assetId={alert?.assetId}
          variant="teaser"
          presentationArea="Alert"
          setSelectedAsset={() => {}}
          alertTime={alert?.seconds}
        />
      );
  }
};

const getAlertFullView = (alert, isNewAlert, batchReviewComplete, setBatchReviewComplete) => {
  switch (alert.type) {
    case batchQaType:
    case batchCustomerType:
      return (
        <FormLoader
          batchInfo={alert}
          formIdProps={alert.type}
          newAlert={isNewAlert}
          batchReviewComplete={batchReviewComplete}
          setBatchReviewComplete={setBatchReviewComplete}
        />
      );
    case invoiceSent:
      return <InvoiceResponse alert={alert} />;
    default:
      return (
        <Asset
          assetId={alert?.assetId}
          variant="full"
          presentationArea="Alert"
          setSelectedAsset={() => {}}
          alertTime={alert?.seconds}
        />
      );
  }
};
