import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import {useContent} from '../../utils/hooks/useContent';

export const ReadOnlyNotice = () => {
  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  return (
    <Box mt={2} mb={2}>
      <Chip color="disabled" label={getUiText('read_only')}></Chip>
    </Box>
  );
};
