import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BinOrSquare from './BinOrSquare';
import {useContent} from '../../utils/hooks/useContent';
import materialsPrepRed from '../../assets/materialsPrepRed.svg';
import materialsPrep from '../../assets/materialsPrep.svg';
import assembledRed from '../../assets/assembledRed.svg';
import assembled from '../../assets/assembled.svg';
import readyRed from '../../assets/readyRed.svg';
import ready from '../../assets/ready.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    borderRadius: '2px',
  },
  cardLabel: {
    fontWeight: 700,
    fontSize: 14,
  },
  cardDetails: {
    fontSize: 14,
    fontWeight: 400,
  },
}));

export const Milestone = ({data, materials, pullTypeSim, index}) => {
  const classes = useStyles();
  const {label, binsSquares} = data;

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const [milestoneStatus, setMilestoneStatus] = useState({});

  // Determine if red wires have been introduced into the simulation.
  // @todo: Add more complex business logic to determine if a bot and/or bin
  // square has started actively working on red wire variants.
  // See https://savaslabs.teamwork.com/#/tasks/30007910.
  const hasRed = 'wiresRed' in materials || 'rawMaterialCommunicationSquare-wiresRed' in materials;

  return (
    <Paper className={classes.paper} elevation={4}>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body1" component="h3" gutterBottom className={classes.cardLabel}>
              {getUiText(label)}
            </Typography>
            <Typography variant="body2" align="left" className={classes.cardDetails}>
              {getUiText(`${label}_info`)}
            </Typography>
          </Grid>
          <Grid item xs={8} container spacing={1}>
            {binsSquares.map((element, index) => (
              <Grid item xs={12} key={`${element.label}${index}`}>
                <BinOrSquare
                  {...element}
                  popover={false}
                  dashboard
                  materials={materials}
                  milestoneStatus={milestoneStatus}
                  setMilestoneStatus={setMilestoneStatus}
                  pullType={pullTypeSim}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="center" alignContent="center" height="100%">
              <img src={getImage(index, hasRed)} alt={label} style={{maxHeight: '100%', maxWidth: '100%'}} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const getImage = (index, hasRed) => {
  switch (index) {
    case 0:
      return hasRed ? materialsPrepRed : materialsPrep;
    case 1:
      return hasRed ? assembledRed : assembled;
    case 2:
      return hasRed ? readyRed : ready;
    default:
      return;
  }
};
