import {useContext} from 'react';
import {UserContext} from '../../context/UserContext';
import {UiTextContext} from '../../context/UiTextContext';
import {fieldNames} from '../constants';

export const useContent = () => {
  const defaultLanguageCode = 'en';
  const userContext = useContext(UserContext);
  const uiTextContext = useContext(UiTextContext);
  const translationKey = uiTextContext.translationKey;
  const selectedLanguage = userContext?.language ? userContext?.language : defaultLanguageCode;

  const getFieldName = (table, fieldName, lang = selectedLanguage) => {
    switch (fieldName) {
      case 'uid':
        return fieldNames[table][fieldName];
      default:
        const translatedField = fieldNames?.[table]?.[fieldName]?.[lang];
        const defaultField = fieldNames?.[table]?.[fieldName]?.[defaultLanguageCode];
        const noTranslationField = fieldNames?.[table]?.[fieldName];
        return translatedField ? translatedField : defaultField ? defaultField : noTranslationField;
    }
  };

  const getUiText = (key, fallback = key, lang = selectedLanguage) => {
    const translatedText = translationKey?.[key]?.[lang];
    const defaultText = translationKey?.[key]?.[defaultLanguageCode];
    // @todo Remove * from UI text without translations.
    // @todo Should we fall back to English if no translation is available?
    return translatedText ? translatedText : defaultText ? `${defaultText}*` : `${fallback}*`;
  };

  return [getFieldName, getUiText];
};
