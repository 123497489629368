import React, {useContext, useEffect, useState} from 'react';
import {SocketContext} from '../../../context/SocketContext';
import {useSocketChannel} from '../../../utils/hooks/useSocketChannel';
import {Status} from '../../../components/Status';
import {useContent} from '../../../utils/hooks/useContent';
import {UserContext} from '../../../context/UserContext';
import {getSimulationType} from '../../../utils/helpers';
import AdminControl from './AdminControl';
import Card from './Card';
import Station from './Station';

const ProductionRole = ({
  channel,
  adminControl = false,
  milestoneStatus = null,
  statusLifter = false,
  station = false,
  setMilestoneStatus = () => {},
  beforeArrowLong = false,
  afterArrowLong = false,
  notABot = false,
  activeIf,
  hideBeforeArrow = false,
  hideAfterArrow = false,
  style,
  videoUrl,
}) => {
  const socketContext = useContext(SocketContext);
  // eslint-disable-next-line no-unused-vars
  const [data, start, pause] = useSocketChannel(channel, socketContext.socket);
  const [status, setStatus] = useState('on break');

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  //@todo adjust socket event so it only returns this production role data, not all.
  useEffect(() => {
    socketContext.socket.emit('get bot state');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWorkComplete = (batchOutputQty, batchWipQty, processTime, timeSpentOnWip, isBlocked) => {
    let totalTime;
    let spentTime;
    // For stations, we show work complete on current unit, not batch.
    if (station) {
      totalTime = processTime;
      spentTime = timeSpentOnWip;
    } else {
      totalTime = batchOutputQty * processTime;
      spentTime = processTime * batchWipQty + timeSpentOnWip;
    }

    let workCompletePercent = (spentTime / totalTime) * 100;

    // Display full completion between batches/progress.
    if (workCompletePercent === 0 && !isBlocked) {
      workCompletePercent = 100;
    }

    return workCompletePercent;
  };

  // Check if this role for this company uses pallets.
  const userContext = useContext(UserContext);
  const currentCompanyId = userContext.companyId;
  const pullTypeSim = getSimulationType(currentCompanyId) === 'pushType';
  const usesPallet = pullTypeSim && (data.role === 'stakingPressOperator' || data.role === 'solderer');

  const workComplete = getWorkComplete(
    data.batchOutputQty,
    data.batchWipQty,
    data.processTime,
    data.timeSpentOnWIP,
    data.isBlocked
  );

  useEffect(() => {
    if (data && !isEmpty(data)) {
      setStatus(data.onBreak ? `on break` : data.isBlocked ? `blocked` : `active`);
    }
  }, [data, data.onBreak, data.isBlocked]);

  // If the purpose of this component is to lift state, lift state.
  useEffect(() => {
    if (statusLifter) {
      milestoneStatus[channel] = status;
      setMilestoneStatus(milestoneStatus);
    }
  }, [statusLifter, status, channel, milestoneStatus, setMilestoneStatus]);

  if (statusLifter) {
    return <>{status !== 'active' && <Status status={status} showLabel={false} />}</>;
  } else {
    return (
      <>
        {adminControl ? (
          <AdminControl onBreak={data.onBreak} label={getUiText(data.label)} pause={pause} start={start} />
        ) : station ? (
          <Station
            channel={channel}
            title={getUiText(data.label ?? channel)}
            status={status}
            workComplete={workComplete}
            loading={notABot ? false : isEmpty(data)}
            batchWipQty={data.batchWipQty}
            usesPallet={usesPallet}
            beforeArrowLong={beforeArrowLong}
            afterArrowLong={afterArrowLong}
            notABot={notABot}
            activeIf={activeIf}
            hideBeforeArrow={hideBeforeArrow}
            hideAfterArrow={hideAfterArrow}
            style={style}
            videoUrl={videoUrl}
            processTime={data.processTime}
          />
        ) : (
          <Card
            title={getUiText(data.label ?? channel)}
            status={status}
            workComplete={workComplete}
            workCompleteLabel={getUiText('work_completed')}
            palletDescriptionLabel={getUiText('pallet_description')}
            usesPallet={usesPallet}
            processTime={data.processTime}
          />
        )}
      </>
    );
  }
};

export default ProductionRole;

const isEmpty = (obj) => {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0;
};
