import React, {useContext, useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {useApi} from '../../utils/hooks/useApi';
import {useContent} from '../../utils/hooks/useContent';
import {UserContext} from '../../context/UserContext';
import {ReadOnlyNotice} from './ReadOnlyNotice';
import {Instructions} from './Instructions';

// Get an array of integers.
const rows = Array(20)
  .fill()
  .map((x, i) => i + 1);

export const Inspection = () => {
  const userContext = useContext(UserContext);
  const companyId = userContext.companyId;
  const simulationStartTime = userContext.simulationStartTime;
  const [getFieldName, getUiText] = useContent();
  const [formattedFormData, setFormattedFormData] = useState([]);

  const columns = [
    getUiText('passed_and_shipped'),
    getUiText('rejected'),
    getUiText('exposed_copper'),
    getUiText('melted_insulation'),
    getUiText('discolored_terminal_tops'),
    getUiText('insulation_spacing'),
  ];

  const url = `/api/forms/?${getFieldName('forms', 'uid')}=modelAQABatchAlert-${companyId}-${simulationStartTime}`;
  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);

  useEffect(() => {
    if (loaded && result) {
      const formData = result?.[0]?.data;
      setFormattedFormData(formData ? formatFormData(formData) : []);
    }
  }, [loaded, result]);

  return (
    <>
      <ReadOnlyNotice />
      <Instructions text={getUiText('worksheet_instructions_inspection_document')} />
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" aria-label="Product inspection worksheet">
          <TableHead>
            <TableRow>
              <TableCell>{getUiText('model_a_inspected')}</TableCell>
              {columns.map((column) => (
                <TableCell align="center">{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const rowData = formattedFormData?.[row - 1];
              const category = rowData?.category;
              const approved = category === 'approved';
              const rejected = category === 'rejected';
              const defects = getDefectsByColumn(rowData, columns);
              return (
                <TableRow key={`Min ${row}`}>
                  <TableCell component="th" scope="row">
                    #{row}
                  </TableCell>
                  {columns.map((column, index) => {
                    return (
                      <TableCell align="center" key={`${row}-${column}-${index}`}>
                        {index < 2 && (
                          <Checkbox
                            checked={index === 0 ? approved : rejected}
                            inputProps={{'aria-label': 'approved'}}
                            disabled
                          />
                        )}
                        {index >= 2 && <>{defects?.[column]}</>}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const formatFormData = (flattenedData) => {
  const fieldNames = Object.keys(flattenedData);
  let inspectedModelAs = [{}];

  fieldNames.forEach((element) => {
    if (element !== 'formId') {
      // Divide the flattened data by the submit field, which is equal to true.
      if (flattenedData[element] === true) {
        inspectedModelAs.push({});
      } else {
        switch (flattenedData[element]) {
          case 'approve':
            inspectedModelAs[inspectedModelAs.length - 1]['category'] = 'approved';
            break;
          case 'reject':
            inspectedModelAs[inspectedModelAs.length - 1]['category'] = 'rejected';
            break;
          default:
            inspectedModelAs[inspectedModelAs.length - 1][element] = flattenedData[element];
            break;
        }
      }
    }
  });
  return inspectedModelAs;
};

const getDefectsByColumn = (inspectionData, columns) => {
  if (!inspectionData) {
    return;
  }
  if (inspectionData.category === 'approved') {
    return;
  }
  let defects = {};
  const fields = Object.keys(inspectionData);
  fields.forEach((key) => {
    if (key.includes('exposed-copper')) {
      defects[columns[2]] = inspectionData[key];
    }
    if (key.includes('melted-insulation')) {
      defects[columns[3]] = inspectionData[key];
    }
    if (key.includes('discolored-terminal-tops')) {
      defects[columns[4]] = inspectionData[key];
    }
    if (key.includes('insulation-spacing')) {
      defects[columns[5]] = inspectionData[key];
    }
  });
  return defects;
};
