import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {ControlledInput} from './Input';
import {useContent} from '../../utils/hooks/useContent';
import {getFormatedMinutesAndSeconds} from '../../utils/helpers';

export const CalculatingTable = ({
  fields,
  handleFieldChange,
  rows,
  columns,
  label,
  rowPrefix,
  disabled,
  step = 1,
  minutesAndSeconds = false,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  // Show integers for inventory, two decimal points for process time.
  const fixedDecimals = step === 1 ? 0 : 2;

  const getSubtotal = (column) => {
    let subtotal = 0;
    // If the field has a value, add it to the subtotal.
    rows.map((row) => {
      const firstNumber = parseFloat(fields[`${column}${row}`]);
      // Second number only used for Process Time Worksheet
      const secondNumber = parseFloat(fields[`${column}${row}-seconds`]);
      if (firstNumber || secondNumber) {
        // For the case of calculating minutes and seconds on the Process time worksheet:
        if (minutesAndSeconds) {
          // First number is minutes.
          let accumulator = firstNumber ? subtotal + firstNumber * 60 : subtotal;
          // Second number is seconds.
          accumulator = secondNumber ? accumulator + secondNumber : accumulator;

          return (subtotal = accumulator);
        }
        // For the case of Work-In-Process Inventory worksheet:
        return (subtotal += firstNumber);
      }
    });
    return subtotal > 0 ? subtotal.toFixed(fixedDecimals) : '';
  };

  const getAverage = (column) => {
    // Determine how many of the rows have values entered.
    let numberOfEntries = 0;
    rows.map((row) =>
      parseFloat(fields[`${column}${row}`] || parseFloat(fields[`${column}${row}-seconds`]))
        ? (numberOfEntries += 1)
        : null
    );
    const average = getSubtotal(column) / numberOfEntries;
    const formattedAverage = average.toFixed(1);
    return numberOfEntries > 0 ? formattedAverage : '';
  };

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table aria-label="Inventory worksheet">
        <TableHead>
          <TableRow>
            <TableCell>{label}</TableCell>
            {columns.map((column) => (
              <TableCell align="center">{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow key={`Min ${row}`}>
                <TableCell component="th" scope="row">
                  {rowPrefix}
                  {row}
                </TableCell>
                {columns.map((column) => (
                  <TableCell align="right">
                    <Grid container spacing={1}>
                      <Grid item xs={minutesAndSeconds ? 5 : 12}>
                        <ControlledInput
                          id={`${column}${row}`}
                          type="number"
                          value={fields[`${column}${row}`]}
                          handleFieldChange={handleFieldChange}
                          variant="standard"
                          disabled={disabled}
                          inputProps={{step: step, placeholder: minutesAndSeconds ? 'm' : null}}
                        />
                      </Grid>

                      {minutesAndSeconds && (
                        <>
                          <Grid item xs={1}>
                            :
                          </Grid>
                          <Grid item xs={6}>
                            <ControlledInput
                              id={`${column}${row}-seconds`}
                              type="number"
                              value={fields[`${column}${row}-seconds`]}
                              handleFieldChange={handleFieldChange}
                              variant="standard"
                              disabled={disabled}
                              inputProps={{step: step, placeholder: minutesAndSeconds ? 'ss' : null}}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell component="th" scope="row"></TableCell>
            {columns.map(() => (
              <TableCell></TableCell>
            ))}
          </TableRow>
          <TableRow key="subtitle">
            <TableCell component="th" scope="row">
              <strong>{getUiText('subtotal')}</strong>
            </TableCell>
            {columns.map((column) => (
              <TableCell align="center">
                {minutesAndSeconds ? getFormatedMinutesAndSeconds(getSubtotal(column)) : getSubtotal(column)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow key="average">
            <TableCell component="th" scope="row">
              <strong>{getUiText('averages')}</strong>
            </TableCell>
            {columns.map((column) => (
              <TableCell align="center">
                {minutesAndSeconds ? getFormatedMinutesAndSeconds(getAverage(column)) : getAverage(column)}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
