import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Welcome({roleName, welcome, intro}) {
  return (
    <>
      <Typography variant="body1" gutterBottom align="center">
        {welcome}
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        {intro}
      </Typography>
      <Typography variant="h4" gutterBottom align="center">
        {roleName}
      </Typography>
    </>
  );
}
