import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {UserContext} from '../context/UserContext';
import {useApi} from '../utils/hooks/useApi';
import {useContent} from '../utils/hooks/useContent';

const useStyles = makeStyles((theme) => ({
  company: {
    fontWeight: 500,
    fontSize: 24,
  },
}));

const Company = function () {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const companyId = userContext.companyId;
  const [getFieldName] = useContent();

  const url = `/api/companies`;

  // eslint-disable-next-line no-unused-vars
  const [result, loading, loaded, error, refresh, setResult] = useApi(url);

  const activeCompany = result?.filter((company) => company[getFieldName('companies', 'uid')] === companyId)?.[0];
  const companyName = activeCompany?.[getFieldName('companies', 'name')];

  return (
    <Typography component="h2" variant="h6" className={classes.company}>
      {companyName} Co.
    </Typography>
  );
};
export default Company;
