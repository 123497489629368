import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTheme} from '@material-ui/core/styles';
import {UserContext} from '../context/UserContext';
import Chip from '@material-ui/core/Chip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import {Typography} from '@material-ui/core';
import {getSimulationType} from '../utils/helpers';
import {useContent} from '../utils/hooks/useContent';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 12,
  },
  chip: {
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 700,
  },
  textOnly: {
    letterSpacing: 0.3,
  },
}));

export const Status = ({status, showLabel = true, textOnly = false, fullWidth = true}) => {
  const classes = useStyles();
  const {palette} = useTheme();
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const userContext = useContext(UserContext);
  const companyId = userContext.companyId;
  const simulationType = getSimulationType(companyId);
  const pullTypeSim = simulationType === 'pullType';

  let label;
  switch (status) {
    case 'active':
      label = 'active';
      break;
    case 'blocked':
      label = 'blocked';
      break;
    case 'on break':
      label = 'on break';
      break;
    default:
      return <></>;
  }

  let variant;
  if (showLabel) {
    variant = textOnly ? 'textOnly' : 'chip';
  } else {
    variant = 'icon';
  }

  const color = palette?.[status]?.main;

  switch (variant) {
    case 'chip':
      return (
        <Chip
          label={getUiText(label)}
          style={{background: color, width: fullWidth ? '100%' : 'auto'}}
          size="small"
          className={classes.chip}
        />
      );
    case 'textOnly':
      return (
        <Typography variant="overline" style={{color: color}} className={classes.textOnly}>
          {getUiText(label)}
        </Typography>
      );
    case 'icon':
      return (
        <>
          {pullTypeSim && status === 'blocked' ? (
            <FiberManualRecordOutlinedIcon fontSize="small" className={classes.icon} style={{fill: color}} />
          ) : (
            <FiberManualRecordIcon fontSize="small" className={classes.icon} style={{fill: color}} />
          )}
        </>
      );
    default:
      return <></>;
  }
};
