import React, {useContext} from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import {CalculatingTable} from './CalculatingTable';
import {ControlledInput} from './Input';
import {UserContext} from '../../context/UserContext';
import {pushCompanyId} from '../../utils/constants';
import {ReadOnlyNotice} from './ReadOnlyNotice';
import {useContent} from '../../utils/hooks/useContent';
import {Instructions} from './Instructions';

const rows = ['1', '2', '3'];

export const ProcessTime = ({fields, handleFieldChange, disabled}) => {
  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const userContext = useContext(UserContext);
  const companyId = userContext.companyId;

  // Push Co does not have a Presolderer.
  const columns =
    companyId === pushCompanyId
      ? [getUiText('terminal_staking'), getUiText('wire_stripping'), getUiText('assembly'), getUiText('soldering')]
      : [
          getUiText('terminal_staking'),
          getUiText('wire_stripping'),
          getUiText('presoldering'),
          getUiText('assembly'),
          getUiText('soldering'),
        ];

  const numberStep = 1;

  return (
    <Box minWidth={600}>
      {disabled && <ReadOnlyNotice />}
      <Instructions text={getUiText('worksheet_instructions_process_time')} />
      <CalculatingTable
        rows={rows}
        columns={columns}
        fields={fields}
        handleFieldChange={handleFieldChange}
        label="Study"
        rowPrefix="#"
        disabled={disabled}
        step={numberStep}
        minutesAndSeconds={true}
      />
      <Box mt={3}>
        <Paper variant="outlined">
          <Box p={2}>
            <Grid container spacing={2} xs={12}>
              <Grid item>
                <ControlledInput
                  id="cycleTime"
                  type="number"
                  value={`${fields.cycleTime}`}
                  label={getUiText('cycle_time')}
                  handleFieldChange={handleFieldChange}
                  disabled={disabled}
                  inputProps={{step: numberStep}}
                />
              </Grid>
              <Grid item>
                <ControlledInput
                  id="processAvg"
                  type="number"
                  value={`${fields.processAvg}`}
                  label={getUiText('process_time')}
                  helpText={getUiText('add_five_averages_together')}
                  handleFieldChange={handleFieldChange}
                  disabled={disabled}
                  inputProps={{step: numberStep}}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};
