import {useState, useContext, useEffect} from 'react';
import {UserContext} from '../../context/UserContext';
import {useLocalStorage} from './useLocalStorage';

export const useChecklist = () => {
  // Store checked items in localStorage.
  const userContext = useContext(UserContext);
  const {companyId, roleId, simulationStartTime} = userContext;
  const [storedChecked, setStoredChecked] = useLocalStorage(
    `storedChecked-${companyId}-${roleId}-${simulationStartTime}`,
    ''
  );
  const [checked, setChecked] = useState(storedChecked ? storedChecked : []);

  // Sync local state and localStorage.
  useEffect(() => {
    setStoredChecked(checked);
  }, [setChecked, checked, setStoredChecked]);

  const addToChecked = (id) => {
    // Add to state only if item doesn't already exist.
    if (!checked.includes(id)) {
      setChecked([...checked, id]);
    }
  };

  const removeFromChecked = (id) => {
    // Remove item from state if unchecked.
    const newState = checked.filter((action) => action !== id);
    setChecked([...newState]);
  };

  const handleChecklist = (e) => {
    let checkedItem = e.target;

    checkedItem.checked ? addToChecked(checkedItem.id) : removeFromChecked(checkedItem.id);
  };

  return [checked, handleChecklist];
};
