import React from 'react';
import ImageGallery from 'react-image-gallery';

// Add s3 bucket url
const S3_BUCKET_URL = 'https://savaslabs-tatham.s3.us-east-2.amazonaws.com/';

export const Gallery = function ({airtableAttachments, alt}) {
  const preparedImages = airtableAttachments
    ?.map((fileName) => {
      const imageUrl = `${S3_BUCKET_URL}${fileName}`;
      return {
        original: imageUrl,
        thumbnail: imageUrl,
        originalAlt: alt,
        thumbnailAlt: alt,
      };
    })
    .filter((image) => image !== null);

  return (
    <ImageGallery
      items={preparedImages}
      showPlayButton={false}
      showIndex={preparedImages?.length > 1}
      showThumbnails={preparedImages?.length > 1}
    />
  );
};
