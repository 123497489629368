import React, {useState, useEffect, useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useContent} from '../../utils/hooks/useContent';
import {Grid, Box, Typography, Paper} from '@material-ui/core';
import {ScatterPlot} from '@nivo/scatterplot';
import {SocketContext} from '../../context/SocketContext';
import {useSocketChannel} from '../../utils/hooks/useSocketChannel';

const useStyles = makeStyles(() => ({
  cardLabel: {
    fontWeight: 500,
    fontSize: 14,
    alignSelf: 'start',
  },
}));

export const Graph = ({itemSize}) => {
  const classes = useStyles();
  const socketContext = useContext(SocketContext);
  const [data] = useSocketChannel('modelAs', socketContext.socket);

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();
  // Graph cannot display without data.
  const dummyData = [{id: 1, data: [{x: 0, y: 0}]}];
  const [graphData, setGraphData] = useState(dummyData);

  // Format data into what nivo expects.
  useEffect(() => {
    if (data?.length > 0) {
      let formattedData = [{id: 1}];
      formattedData[0]['data'] = data?.map((modelA, index) => {
        return {
          x: Number.parseFloat(modelA / 60).toFixed(2),
          y: index + 1,
        };
      });

      setGraphData(formattedData);
    }
  }, [data]);

  return (
    <Box clone order={5} display={itemSize <= 4 ? '' : 'flex'}>
      <Grid item xs={12}>
        <Paper style={{width: '100%'}}>
          <Box pt={2} pb={2} p={2}>
            <Grid container spacing={3}>
              <Grid clone item style={{height: '210px', width: '99%'}}>
                <Typography variant="body1" component="h3" gutterBottom className={classes.cardLabel}>
                  {getUiText('model_a_shipped_over_time')}
                </Typography>
                <Box display="flex" justifyContent="center">
                  <ScatterPlot
                    margin={{top: 10, right: 10, bottom: 70, left: 20}}
                    data={graphData}
                    xScale={{
                      type: 'linear',
                      min: 0,
                      max: 60,
                    }}
                    width={itemSize <= 4 ? 550 : 400}
                    height={200}
                    nodeSize={10}
                    animate={false}
                    blendMode={'multiply'}
                    yScale={{type: 'linear', min: 0, max: 12}}
                    xFormat={(d) => `min ${d}`}
                    yFormat={(d) => `${d} shipped`}
                    axisBottom={{
                      legend: 'Minutes',
                      legendPosition: 'middle',
                      legendOffset: 30,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Box>
  );
};
