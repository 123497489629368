import React from 'react';
import {Typography} from '@material-ui/core';

export const Banner = function ({text}) {
  return (
    <Typography variant="h2" align="center">
      {text}
    </Typography>
  );
};
