import React, {useEffect, useContext} from 'react';
import Box from '@material-ui/core/Box';
import BinOrSquare from './BinOrSquare';
import {SocketContext} from '../../context/SocketContext';
import {useContent} from '../../utils/hooks/useContent';
import {useSocketChannel} from '../../utils/hooks/useSocketChannel';

export const Bins = ({binsSquares = [], pullType}) => {
  const socketContext = useContext(SocketContext);
  const [materials] = useSocketChannel('materials', socketContext.socket);

  useEffect(() => {
    socketContext.socket.emit('get materials');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  if (pullType) {
    return (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <BinOrSquare {...binsSquares[0]} materials={materials} popover={false} pullType />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
      <Box component="ul" display="flex" my={0} py={0}>
        {binsSquares?.map((element, index) => (
          <Box mr={1} key={`${element.label}${index}`}>
            <BinOrSquare {...element} materials={materials} popover={false} pullType={pullType} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
