import React, {useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {UserContext} from '../../context/UserContext';
import {RoleHighlighter} from '../../components/Highlighter';
import {useContent} from '../../utils/hooks/useContent';

const UserInfo = function () {
  const userContext = useContext(UserContext);

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  return (
    <Box>
      <Typography variant="h6" component="h1" display="inline" color="inherit" noWrap>
        <RoleHighlighter text={userContext.roleName} />
      </Typography>{' '}
      <Box component="span" pl={1}></Box>
      <Typography variant="subtitle2" component="h2" display="inline" color="textSecondary" noWrap>
        {getUiText('os_book')}
      </Typography>
    </Box>
  );
};
export default UserInfo;
