import React, {useContext} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {UserContext} from '../context/UserContext';
import {useContent} from '../utils/hooks/useContent';

export const LanguageSelect = () => {
  const userContext = useContext(UserContext);
  const language = userContext.language ? userContext.language : 'en';

  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const handleChange = (event) => {
    userContext.setLanguage(event.target.value);
  };

  return (
    <FormControl>
      <InputLabel id="language">{getUiText('language')}</InputLabel>
      <Select labelId="language-label" id="language" value={language} onChange={handleChange}>
        <MenuItem value={'en'}>English</MenuItem>
        <MenuItem value={'de'}>Deutsch</MenuItem>
      </Select>
    </FormControl>
  );
};
