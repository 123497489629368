import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Box, Typography, Paper} from '@material-ui/core';
import {getMaterialQuantity} from '../../utils/helpers';
import onHand from '../../assets/onHand.svg';
import onHandRed from '../../assets/onHandRed.svg';
import {useContent} from '../../utils/hooks/useContent';
import {MaterialBin} from './MaterialBin';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: '2px',
  },
  cardLabel: {
    fontWeight: 700,
    fontSize: 14,
  },
  cardDetails: {
    fontSize: 14,
    fontWeight: 400,
  },
  binsSquareName: {
    color: '#737373',
    fontWeight: 500,
    fontSize: 12,
  },
  binQuantity: {
    fontWeight: 500,
    fontSize: 24,
  },
}));

const getSixteenInchWireQuantity = (fourInchWireQuantity) => {
  const quanitity = fourInchWireQuantity / 4;
  return quanitity.toFixed(2);
};

export const Materials = ({pullTypeSim, data, materialsToDisplay}) => {
  const classes = useStyles();
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const fourInchWireQuantity = pullTypeSim ? getMaterialQuantity('wires', data) : data.wires;
  const sixteenInchWireQuantity = getSixteenInchWireQuantity(fourInchWireQuantity);
  const terminalsQuantity = getMaterialQuantity('terminals', data);
  const boardsQuantity = getMaterialQuantity('boards', data);
  let materials = [
    {label: `16" ${getUiText('wires')}`, quanitity: sixteenInchWireQuantity},
    {label: `${getUiText('terminals')}`, quanitity: terminalsQuantity},
    {label: `${getUiText('boards')}`, quanitity: boardsQuantity},
  ];
  if (data.wiresRed && !pullTypeSim) {
    materials.splice(1, 0, {
      label: `16" ${getUiText('wires')} (${getUiText('red')})`,
      quanitity: getSixteenInchWireQuantity(data.wiresRed),
    });
  }
  return (
    <Grid item xs={12}>
      <Paper elevation={4} className={classes.paper}>
        <Box pt={2} pb={2} p={2} width="100%">
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="body1" component="h3" align="left" className={classes.cardLabel} gutterBottom>
                {getUiText(materialsToDisplay)}
              </Typography>
              <Typography variant="body2" align="left" className={classes.cardDetails}>
                {getUiText(`${materialsToDisplay}_info`)}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Box display="flex" justifyContent="center" flexWrap={true}>
                {materials.map((material) => (
                  <Box px={0.5} key={material.label}>
                    <MaterialBin
                      label={material.label}
                      quantity={material.quanitity}
                      colorPositive={false}
                      colorNegative={materialsToDisplay === 'raw_material'}
                      uppercase={false}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="center" alignContent="center" height="100%">
                <img
                  src={data.wiresRed || pullTypeSim ? onHandRed : onHand}
                  alt="raw materials"
                  style={{maxHeight: '100%', maxWidth: '100%'}}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};
