import React, {useState} from 'react';
import {Typography} from '@material-ui/core';
import {Asset} from '../../components/Asset';
import {useContent} from '../../utils/hooks/useContent';

export const Documents = ({assets}) => {
  const [selectedAsset, setSelectedAsset] = useState();
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  return (
    <>
      {assets && !selectedAsset && (
        <>
          <Typography component="h3" variant="overline" gutterBottom>
            {getUiText('documents')}
          </Typography>
          {assets?.map((assetId) => (
            <Asset
              key={assetId}
              assetId={assetId}
              variant="teaser"
              setSelectedAsset={setSelectedAsset}
              presentationArea="Reference"
            />
          ))}
        </>
      )}
      {selectedAsset && (
        <Asset
          key={selectedAsset}
          assetId={selectedAsset}
          variant="full"
          setSelectedAsset={setSelectedAsset}
          presentationArea="Reference"
        />
      )}
    </>
  );
};
