import {useState, useContext, useEffect} from 'react';
import {Paper, Box, Typography, Button} from '@material-ui/core';
import {useContent} from '../../utils/hooks/useContent';
import {SocketContext} from '../../context/SocketContext';
import {useSocketChannel} from '../../utils/hooks/useSocketChannel';

export const InvoiceResponse = ({alert}) => {
  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();
  const [isPaid, setIsPaid] = useState(false);

  const socketContext = useContext(SocketContext);
  const socket = socketContext.socket;

  const handleClick = () => {
    setIsPaid(true);
    socket.emit('invoice paid', {invoiceId: alert.id});
  };

  // eslint-disable-next-line no-unused-vars
  const [paidInvoices] = useSocketChannel('paid invoices', socket);

  useEffect(() => {
    socketContext.socket.emit('get paid invoices');
    // Run only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(paidInvoices)) {
      if (paidInvoices?.includes(alert.id)) {
        setIsPaid(true);
      }
    }
  }, [paidInvoices, alert.id]);

  return (
    <Paper square>
      <Box p={2}>
        <Typography variant="body1">
          {getUiText('you_have_received_invoice_for')} {alert.invoiceData.material}: {alert.invoiceData.quantity} @{' '}
          {'$'}
          {alert.invoiceData.cost}, {getUiText('for_a_total_of')} ${alert.invoiceData.total}.
        </Typography>
        <Box align="right" mt={2}>
          <Button variant="contained" color="primary" onClick={handleClick} disabled={isPaid}>
            {isPaid ? <>{getUiText('payment_complete')}</> : <>{getUiText('approve_and_pay')}</>}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
