import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import {CustomCircularProgress} from '../../../components/CustomCircularProgress';
import {Status} from '../../../components/Status';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    fontSize: 14,
  },
}));

const CustomCard = ({title, status, workComplete, processTime}) => {
  const classes = useStyles();
  return (
    <CardHeader
      avatar={
        <CustomCircularProgress
          value={workComplete ? workComplete : 0}
          hasLabel
          status={status}
          processTime={processTime}
          thickness={12}
          size={44}
        />
      }
      title={title}
      subheader={<Status status={status} fullWidth={false} />}
      classes={{title: classes.title}}
      titleTypographyProps={{gutterBottom: true}}
    />
  );
};

export default CustomCard;
