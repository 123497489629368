import React from 'react';
import TextField from '@material-ui/core/TextField';

const EditName = function ({name, setName, label}) {
  const handleTextChange = (e) => {
    setName(e.target.value);
  };

  return (
    <TextField
      margin="dense"
      autoFocus
      id="name"
      type="text"
      value={name}
      fullWidth
      onChange={handleTextChange}
      label={label}
    />
  );
};
export default EditName;
