import React, {useState, createContext, useEffect} from 'react';

export const TimeContext = createContext();

export const TimeProvider = (props) => {
  const [seconds, setSeconds] = useState(false);
  const [currentMinute, setCurrentMinute] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  useEffect(() => {
    const calculatedMinute = seconds ? Math.floor(seconds / 60) : false;
    if (currentMinute !== calculatedMinute) {
      setCurrentMinute(calculatedMinute);
    }
  }, [seconds, currentMinute]);

  return (
    <TimeContext.Provider
      value={{
        seconds: seconds,
        isTimerRunning: isTimerRunning,
        currentMinute,
        setSeconds,
        setIsTimerRunning,
      }}
    >
      {props.children}
    </TimeContext.Provider>
  );
};
