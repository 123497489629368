import React, {useContext, useEffect} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinkIcon from '@material-ui/icons/Link';
import ListItemText from '@material-ui/core/ListItemText';
import {SocketContext} from '../context/SocketContext';
import {UserContext} from '../context/UserContext';
import {useSocketChannel} from '../utils/hooks/useSocketChannel';
import {useContent} from '../utils/hooks/useContent';
import {useRoles} from '../utils/hooks/useRoles';
import {alphabetize} from '../utils/helpers';

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const Roles = function ({links, disableLinksWithNames = false}) {
  const [getFieldName] = useContent();
  const socketContext = useContext(SocketContext);
  const userContext = useContext(UserContext);
  const [roles] = useRoles();

  useEffect(() => {
    if (socketContext.socket) socketContext.socket.emit('get names');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketContext]);

  // eslint-disable-next-line no-unused-vars
  const [data] = useSocketChannel('names', socketContext?.socket);

  useEffect(() => {
    if (data === 'reset') {
      userContext.setRealName('');
    }
  }, [data]);

  // Sort roles alphabetically by role name
  const sortedRoles = alphabetize(roles, `${getFieldName('roles', 'roleName')}`);

  return (
    <>
      {sortedRoles?.length > 0 && (
        <List>
          {sortedRoles.map((role, index) => {
            const id = role[`${getFieldName('roles', 'uid')}`];
            const name = data[id] ? ` – ${data[id]}` : '';

            return (
              <div key={`${id}-${index}`}>
                {links ? (
                  <ListItemLink target="_blank" href={`/?role=${id}`} disabled={disableLinksWithNames && name}>
                    <ListItemIcon>
                      <LinkIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${role[`${getFieldName('roles', 'roleName')}`]}${name}`} />
                  </ListItemLink>
                ) : (
                  <ListItem key={`${id}-${index}`}>
                    {role[`${getFieldName('roles', 'roleName')}`]}
                    {name}
                  </ListItem>
                )}
              </div>
            );
          })}
        </List>
      )}
    </>
  );
};
export default Roles;
