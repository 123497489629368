import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import {AlertContext} from '../../context/AlertContext';
import {Alert} from './Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));

export default function AlertHistory() {
  const classes = useStyles();
  const alertContext = useContext(AlertContext);
  const alerts = alertContext.alerts;

  return (
    <div className={classes.root}>
      {alerts.length < 1 && <MuiAlert>No Alerts</MuiAlert>}
      {alerts.map((alert, index) => (
        <Alert key={alert.id} alert={alert} newAlert={index === 0 && alertContext.hasNewAlert} />
      ))}
    </div>
  );
}
