import React, {useState, useContext} from 'react';
import ImageGallery from 'react-image-gallery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import A1 from '../../assets/ePullModelAs/A1.png';
import A2 from '../../assets/ePullModelAs/A2.png';
import A3 from '../../assets/ePullModelAs/A3.png';
import A4 from '../../assets/ePullModelAs/A4.png';
import A5 from '../../assets/ePullModelAs/A5.png';
import A6 from '../../assets/ePullModelAs/A6.png';
import A7 from '../../assets/ePullModelAs/A7.png';
import A8 from '../../assets/ePullModelAs/A8.png';
import A9 from '../../assets/ePullModelAs/A9.png';
import A10 from '../../assets/ePullModelAs/A10.png';
import A11 from '../../assets/ePullModelAs/A11.png';
import A12 from '../../assets/ePullModelAs/A12.png';
import {SocketContext} from '../../context/SocketContext';
import {ReadOnlyNotice} from './ReadOnlyNotice';
import {useContent} from '../../utils/hooks/useContent';
import {Instructions} from './Instructions';

const options = [
  {
    label: 'Model A1',
    id: 'A1',
    src: A1,
  },
  {
    label: 'Model A2',
    id: 'A2',
    src: A2,
  },
  {
    label: 'Model A3',
    id: 'A3',
    src: A3,
  },
  {
    label: 'Model A4',
    id: 'A4',
    src: A4,
  },
  {
    label: 'Model A5',
    id: 'A5',
    src: A5,
  },
  {
    label: 'Model A6',
    id: 'A6',
    src: A6,
  },
  {
    label: 'Model A7',
    id: 'A7',
    src: A7,
  },
  {
    label: 'Model A8',
    id: 'A8',
    src: A8,
  },
  {
    label: 'Model A9',
    id: 'A9',
    src: A9,
  },
  {
    label: 'Model A10',
    id: 'A10',
    src: A10,
  },
  {
    label: 'Model A11',
    id: 'A11',
    src: A11,
  },
  {
    label: 'Model A12',
    id: 'A12',
    src: A12,
  },
];

export const Order = ({fields, handleFieldChange, disabled}) => {
  // eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const [value, setValue] = useState('');
  const [submittedOrders, setSubmittedOrders] = useState(fields.orders ?? []);
  const [newSubmittedOrder, setNewSubmittedOrder] = useState(false);

  const socketContext = useContext(SocketContext);
  const socket = socketContext.socket;

  // @todo: Show unit price if in scope.
  const showUnitPrice = false;

  const handleChange = (event) => {
    setValue(event.target.value);
    setNewSubmittedOrder(false);
  };

  const isSelected = (id) => {
    return value === id;
  };

  const handleSubmit = () => {
    socket.emit('ePull order placed', {modelAType: value, orderIndex: submittedOrders.length});

    const newSubmittedOrders = [...submittedOrders, value];
    setSubmittedOrders(newSubmittedOrders);
    const proxyEvent = {
      target: {
        id: `orders`,
        value: newSubmittedOrders,
      },
    };
    handleFieldChange(proxyEvent);
    setValue('');
    setNewSubmittedOrder(true);
  };
  return (
    <>
      {disabled && <ReadOnlyNotice />}
      <Instructions text={getUiText('worksheet_instructions_order')} />
      <form>
        <Box mb={3}>
          <Grid container spacing={1}>
            <Grid item xs>
              <Typography variant="body2" component="span">
                {getUiText('order_number')}
                {': '}
              </Typography>
              <Typography variant="h6" component="span">
                {submittedOrders.length + 1}
              </Typography>
            </Grid>
            {showUnitPrice && (
              <Grid item xs>
                <Typography variant="body2" component="span">
                  {getUiText('price_per_unit')}
                  {': '}
                </Typography>
                <Typography variant="h6" component="span">
                  $50
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
        <FormControl component="fieldset" disabled={disabled}>
          <FormLabel component="legend">{getUiText('wire_combination')}</FormLabel>
          <Box mt={2} mb={2}>
            <RadioGroup aria-label="model-a-type-select" name="modelAType" value={value} onChange={handleChange}>
              <Grid container spacing={1}>
                {options.map((option) => (
                  <Grid item xs={3} key={option.id}>
                    <Paper variant={isSelected(option.id) ? undefined : 'outlined'} elevation={8}>
                      <Box clone flexDirection="row" alignItems="flex-start" p={1} height={140}>
                        <FormControlLabel
                          fullWidth
                          value={option.id}
                          control={<Radio />}
                          label={<RadioLabel label={option.label} src={option.src} />}
                        />
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </Box>
        </FormControl>
        <Box mt={2} mb={2} display="flex" alignItems="center">
          <Button variant="contained" color="primary" disabled={!value} onClick={handleSubmit}>
            {getUiText('submit_order')}
          </Button>
          {newSubmittedOrder && <Box m={2}>{getUiText('thanks_for_order')}</Box>}
        </Box>
      </form>
      <Box mt={2} mb={2}>
        <Typography variant="h6" component="h3">
          {getUiText('order_history')}
        </Typography>
        {submittedOrders.length < 1 ? (
          <Typography>{getUiText('no_orders')}</Typography>
        ) : (
          <OrderHistory orders={submittedOrders} getUiText={getUiText} />
        )}
      </Box>
    </>
  );
};

const RadioLabel = ({label, src}) => {
  const items = [
    {
      original: src,
      originalAlt: label,
    },
  ];
  return (
    <span>
      {label}
      <Box maxWidth={130}>
        <ImageGallery
          items={items}
          showPlayButton={false}
          showIndex={false}
          showThumbnails={false}
          showFullscreenButton={false}
        />
      </Box>
    </span>
  );
};

const OrderHistory = ({orders, getUiText}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="order history">
        <TableHead>
          <TableRow>
            <TableCell>{getUiText('order_number')}</TableCell>
            <TableCell>{getUiText('model')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, index) => (
            <TableRow key={order}>
              <TableCell component="th" scope="row">
                #{index + 1}
              </TableCell>
              <TableCell align="left">
                {getUiText('model')} {order}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
