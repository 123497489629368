import React, {useContext} from 'react';
import Box from '@material-ui/core/Box';
import {UserContext} from '../../context/UserContext';
import {getSimulationType} from '../../utils/helpers';
import {pushCompanyId} from '../../utils/constants';
import newPush from '../../assets/productionFloorDiagrams/new-push-floor.png';
import pullePull from '../../assets/productionFloorDiagrams/pull-epull-floor.png';
import push from '../../assets/productionFloorDiagrams/push-floor.png';

export const FloorMap = () => {
  const userContext = useContext(UserContext);
  const companyId = userContext.companyId;
  let image;

  switch (getSimulationType(companyId)) {
    case 'pullType':
      image = pullePull;
      break;
    case 'pushType':
      image = companyId === pushCompanyId ? push : newPush;
      break;
    default:
      image = null;
  }

  return (
    <Box w={1} h={1} display="flex" justifyContent="center" alignItems="center">
      <img alt="Production floor map" src={image} style={{maxWidth: '100%'}} />
    </Box>
  );
};
