import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import TableChartIcon from '@material-ui/icons/TableChart';
import {makeStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {Action} from '../../features/os_book/Action';
import {Responses} from '../../features/os_book/Responses';
import {Documents} from '../../features/os_book/Documents';
import Goal from '../../features/os_book/Goal';
import {Home} from '../../features/os_book/Home';
import OsDrawer from './OsDrawer';
import {OrganizationTab} from './OrganizationTab';
import {BlockTabs, BlockTab} from '../../components/Tabs';
import {useContent} from '../../utils/hooks/useContent';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    width: '100%',
    height: '100%',
  },
  content: {
    padding: 8,
    paddingTop: 20,
    height: 'calc(100vh - 120px)',
    overflowY: 'auto',
    width: '100%',
  },
  flex: {
    display: 'flex',
  },
  button: {
    marginTop: 20,
  },
}));

export default function OsTabs({
  assets,
  responses,
  actions,
  selectedMinute,
  setSelectedMinute,
  selectedResponse,
  setSelectedResponse,
  tabIndex,
  setTabIndex,
}) {
  const [getFieldName, getUiText] = useContent();

  const classes = useStyles();

  const [drawerType, setDrawerType] = useState('minute');

  const handleChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  useEffect(() => {
    if (tabIndex === 3) {
      setDrawerType('response');
      return;
    }
    if (tabIndex === 2) {
      setDrawerType('minute');
      return;
    }
    setDrawerType('disabled');
  }, [tabIndex]);

  const handleReturnToScript = () => {
    setTabIndex(2);
  };

  return (
    <div className={classes.root}>
      <BlockTabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="OS Navigation"
      >
        <BlockTab
          label={
            <Tooltip title={getUiText('instructions')}>
              <ImportContactsOutlinedIcon />
            </Tooltip>
          }
        />
        <BlockTab label={getUiText('role')} />
        <BlockTab label={getUiText('script')} />
        <BlockTab label={getUiText('responses')} />
        <BlockTab label={getUiText('documents')} />
        <BlockTab
          label={
            <Tooltip title={getUiText('org_chart')}>
              <TableChartIcon />
            </Tooltip>
          }
        />
      </BlockTabs>
      <div className={classes.flex}>
        <OsDrawer
          data={drawerType === 'response' ? responses : actions}
          selectedIndex={drawerType === 'response' ? selectedResponse : selectedMinute}
          setSelectedIndex={drawerType === 'response' ? setSelectedResponse : setSelectedMinute}
          drawerType={drawerType}
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <div className={classes.content}>
          {tabIndex === 0 && <Home />}
          {tabIndex === 1 && <Goal />}
          {tabIndex === 2 && actions[selectedMinute]?.[`${getFieldName('prompts', 'action')}`] && (
            <>
              <Action
                action={actions[selectedMinute]?.[`${getFieldName('prompts', 'action')}`]}
                additionalInfo={actions[selectedMinute]?.[`${getFieldName('prompts', 'actionInfo')}`]}
                assetIds={actions[selectedMinute]?.[`${getFieldName('prompts', 'assets')}`]}
                minute={actions[selectedMinute]?.[`${getFieldName('prompts', 'minute')}`]}
              />
            </>
          )}
          {tabIndex === 3 && (
            <>
              <Responses responsesArray={responses} selectedIndex={selectedResponse} />
              <Button variant="contained" color="primary" onClick={handleReturnToScript} className={classes.button}>
                {getUiText('return_to_script')}
              </Button>
            </>
          )}
          {tabIndex === 4 && <Documents assets={assets} />}
          {tabIndex === 5 && <OrganizationTab />}
        </div>
      </div>
    </div>
  );
}
