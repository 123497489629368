import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditName from '../../components/EditName';
import {LanguageSelect} from '../../components/LanguageSelect';
import Welcome from './Welcome';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import {useContent} from '../../utils/hooks/useContent';

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function RoleNameForm({handleNext, roleName, realName, roleId, socket, lang}) {
  //eslint-disable-next-line no-unused-vars
  const [getFieldName, getUiText] = useContent();

  const classes = useStyles();
  const [name, setName] = useState(realName);

  const handleSubmit = (e) => {
    e.preventDefault();
    socket.emit('set name', {name: name, roleId: roleId});
    handleNext();
  };

  useEffect(() => {
    setName(realName);
  }, [realName]);

  return (
    <>
      <div className={classes.text}>
        {roleName && (
          <form onSubmit={handleSubmit}>
            <Box mb={4}>
              <LanguageSelect />
            </Box>
            <Welcome
              roleName={roleName}
              welcome={getUiText('welcome', 'welcome', lang)}
              intro={getUiText('welcome_role', 'welcome_role', lang)}
            />
            <Box mt={3}>
              <Typography variant="body1" gutterBottom>
                {getUiText('enter_first_last', 'enter_first_last', lang)}
              </Typography>
              <Box width={1 / 2} mx="auto">
                <EditName name={name} setName={setName} label={getUiText('full_name', 'full_name', lang)} />
              </Box>
            </Box>
            <div className={classes.buttons}>
              <Button
                disabled={!roleName || !name}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.button}
                endIcon={<ArrowForwardIosOutlinedIcon />}
                type="submit"
              >
                {getUiText('next', 'next', lang)}
              </Button>
            </div>
          </form>
        )}
      </div>
    </>
  );
}
