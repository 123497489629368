import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTheme} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  binsSquareName: {
    fontWeight: 500,
    fontSize: 12,
    color: '#737373',
  },
  prodBin: {
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'uppercase',
    textAlign: 'center',
    lineHeight: 1,
  },
  prodQuantity: {
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: 1,
  },
  binQuantity: {
    fontSize: 24,
    fontWeight: 500,
  },
}));

export const MaterialBin = ({
  label,
  quantity,
  colorPositive = true,
  colorZero = true,
  uppercase = true,
  wrapperClass,
}) => {
  const classes = useStyles();
  const {palette} = useTheme();
  const defaultBackground = '#F4F4F4';

  const getBackgroundColor = (quantity) => {
    if (colorPositive && quantity > 0) {
      return palette.active.light;
    }
    // eslint-disable-next-line eqeqeq
    if (colorZero && quantity == 0) {
      return palette?.['on break']?.light;
    }
    return defaultBackground;
  };

  return (
    <Box p={1} style={{backgroundColor: getBackgroundColor(quantity)}} className={wrapperClass}>
      <Typography variant="body2" className={uppercase ? classes.prodBin : classes.binsSquareName} align="center">
        {label}
      </Typography>
      <Typography variant="h6" className={uppercase ? classes.prodQuantity : classes.binQuantity} align="center">
        {quantity}
      </Typography>
    </Box>
  );
};
